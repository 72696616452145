import { motion } from "framer-motion";

type FullscreenModalProps = {
  children: React.ReactNode;
};

const FullscreenModal: React.FC<FullscreenModalProps> = ({ children }) => {
  return (
    <div
      style={{
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
      }}
      className="w-full h-full bg-gray-900"
    >
      <motion.div
        initial={{ x: -60 }}
        animate={{ x: 0 }}
        exit={{ x: -60 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        className="w-full h-full overflow-auto"
      >
        {children}
      </motion.div>
    </div>
  );
};

export default FullscreenModal;
