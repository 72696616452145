import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import Button from "./Button";
import ModalContext from "./context/ModalContext";
import SubscribeModal from "./modal/SubscribeModal";

import { useTranslation } from "next-i18next";
import apiClient from "../api/apiClient";
import { useSelf } from "../api/hooks";
import { getSubscriptionToTrainer, getTrainerByID } from "../api/wrapper";
import AlertContext from "./context/AlertContext";
import LoginFormModal from "./modal/LoginFormModal";
import { cn } from "../lib/cn";

type SubscribeButtonProps = {
  userId: string;
  size?: "full" | "normal";
  className?: string;
};
const SubscribeButton: React.FC<SubscribeButtonProps> = ({
  userId,
  className,
  size = "normal",
}) => {
  const { t: translate } = useTranslation("common");
  const modalContext = useContext(ModalContext);
  const alert = useContext(AlertContext);
  const self = useSelf();

  const [subscribed, setSubscribed] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");

  const subscriptionQuery = useQuery(
    ["userSubscriptions", "trainer", userId],
    () => getSubscriptionToTrainer(userId)
  );

  const uploaderDataQuery = useQuery(
    `trainer${userId}`,
    () => getTrainerByID(userId),
    { enabled: !!userId }
  );
  const uploaderData = uploaderDataQuery.data;

  useEffect(() => {
    if (subscriptionQuery.data) {
      if (subscriptionQuery.data.status === "active") {
        setSubscribed(true);
        setSubscriptionId(subscriptionQuery.data.id);
      } else {
        setSubscribed(false);
      }
      if (new Date(subscriptionQuery.data.activeUntil) > new Date()) {
        setSubscriptionActive(true);
        setSubscriptionId(subscriptionQuery.data.id);
      }
    }
  }, [subscriptionQuery.data]);

  return (
    <Button
      size={size === "full" ? "full" : "large"}
      className={cn(`${size === "full" ? "mr-8 w-72" : "w-68"}`, className)}
      variant="cart"
      onClick={() => {
        if (self.data) {
          if (subscribed && subscriptionActive) {
            apiClient
              .post(`subscriptions/cancel/${subscriptionId}`)
              .then(() => {
                setSubscribed(false);
                setSubscriptionId("");
              })
              .then(() => subscriptionQuery.refetch());
          } else if (!subscriptionActive) {
            modalContext.show(SubscribeModal, {
              userId: userId,
            });
          } else {
            modalContext.show(SubscribeModal, {
              userId: userId,
              subscribed: true,
            });
          }
        } else {
          modalContext.show(LoginFormModal, {
            normalSignup: true,
            trainerId: userId,
          });
        }
      }}
    >
      {subscribed ? (
        translate("common:unsubscribe")
      ) : (
        <div className="flex flex-col text-lg items-center w-full">
          <span className="text-[1.2rem] mt-1">
            {translate("common:subscribe").toUpperCase()}
          </span>
          <span className="text-normal whitespace-nowrap -mt-1">
            {translate("common:monthly-price-display", {
              priceAndCurrency: `${
                uploaderData?.subscriptionPriceMonthly || 0
              } ${uploaderData?.currency || ""}`,
            })}
          </span>
        </div>
      )}
    </Button>
  );
};

export default SubscribeButton;
