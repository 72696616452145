import React, { useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import CustomNextPage from "../types/CustomNextPage";
import { AlertProvider } from "./context/AlertContext";
import { GlobalsProvider } from "./context/GlobalsContext";
import { ModalProvider } from "./context/ModalContext";
import { WebSocketProvider } from "./context/WebSocketContext";

type AppProvidersProps = {
  page: CustomNextPage<unknown>;
  pageProps: any;
  children: React.ReactNode;
};

const AppProviders: React.FC<AppProvidersProps> = ({
  page,
  pageProps,
  children,
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <GlobalsProvider>
          <AlertProvider>
            <ModalProvider>
              <WebSocketProvider shouldConnect={!!page.ws}>
                {children}
              </WebSocketProvider>
            </ModalProvider>
          </AlertProvider>
        </GlobalsProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default AppProviders;
