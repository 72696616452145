import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import React, { useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import ReactDOM from "react-dom";
import isServer from "../lib/isServer";

import IconChevronDown from "../svg/ic_chevron_down.svg";
import IconChevronUp from "../svg/ic_chevron_up.svg";

type DropdownButton = {
  label: string;
  icon?: React.ReactNode;
  action?: () => void;
  href?: string;
};

type DropdownProps = {
  buttons: DropdownButton[];
  openIcon?: React.ReactNode;
  closedIcon?: React.ReactNode;
};

const Dropdown: React.FC<DropdownProps> = ({
  buttons,
  openIcon,
  closedIcon,
}) => {
  const main = document.getElementsByTagName("main")[0];
  const [isOpen, setIsOpen] = useState(false);
  const [buttonRect, setButtonRect] = useState<DOMRect | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  useOnclickOutside(() => setIsOpen(false), { refs: [ref, menuRef] });

  if (isServer || !main) return null;

  const menu = (
    <AnimatePresence>
      {isOpen && (
        <motion.ul
          ref={menuRef}
          initial={{ scale: 0.95, opacity: 0 }}
          exit={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeOut" }}
          style={
            buttonRect
              ? {
                  right: main.clientWidth - buttonRect.right,
                  top: `calc(${buttonRect.bottom}px + 1rem)`,
                }
              : {}
          }
          className="fixed z-1000 mt-2 py-2 rounded-lg bg-white text-gray-900 origin-top-right"
        >
          {buttons.map((button, i) => {
            const li = (
              <li
                key={`dropdownbutton${i}`}
                className="flex items-center pr-6 py-2 cursor-pointer hover:bg-gray-400 hover:bg-opacity-50"
                onClick={() => {
                  button.action?.();
                  setIsOpen(false);
                }}
              >
                {button.icon && <span className="ml-4">{button.icon}</span>}
                <span className="ml-6 whitespace-nowrap">{button.label}</span>
              </li>
            );

            return button.href ? <Link href={button.href}>{li}</Link> : li;
          })}
        </motion.ul>
      )}
    </AnimatePresence>
  );

  return (
    <div className="relative" ref={ref}>
      <div
        className="cursor-pointer"
        onClick={(e) => {
          setButtonRect(e.currentTarget.getBoundingClientRect());
          setIsOpen(!isOpen);
        }}
      >
        {isOpen
          ? openIcon ?? <IconChevronUp className="w-6" />
          : closedIcon ?? <IconChevronDown className="w-6" />}
      </div>
      {ReactDOM.createPortal(menu, main)}
    </div>
  );
};

export default Dropdown;
