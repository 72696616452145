import { motion } from "framer-motion";
import { useContext } from "react";
import { useTranslation } from "next-i18next";
import { useSelf } from "../../api/hooks";
import IconArrowRight from "../../svg/ic_arrow_right.svg";
import AnimatedTap from "../AnimatedTap";
import Button from "../Button";
import ModalContext from "../context/ModalContext";
import LazyLoadedImg from "../LazyLoadedImg";
import LoginFormModal from "../modal/LoginFormModal";

type HamburgerMenuUserCardProps = {
  onClick: () => void;
  trainerId?: string;
};

const HamburgerMenuUserCard: React.FC<HamburgerMenuUserCardProps> = ({
  onClick,
  trainerId,
}) => {
  const modalContext = useContext(ModalContext);
  const { isLoading, error, data } = useSelf();
  const { t: translate } = useTranslation("common");
  if (!data)
    return (
      <div className="flex flex-col gap-8 justify-around">
        <Button
          onClick={() => {
            modalContext.show(LoginFormModal, {
              normalSignup: true,
              tab: 0,
              trainerId: trainerId,
            });
          }}
        >
          {translate("common:sign-up")}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            modalContext.show(LoginFormModal, {
              normalSignup: true,
              tab: 1,
              trainerId: trainerId,
            });
          }}
        >
          {translate("common:log-in")}
        </Button>
      </div>
    );

  const container = {
    hidden: { opacity: 0, x: -100 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        delay: 0.15,
      },
    },
  };

  return (
    <AnimatedTap>
      <motion.div
        onClick={onClick}
        variants={container}
        initial="hidden"
        animate="show"
        className="flex items-center px-12 py-4"
      >
        <LazyLoadedImg
          className="w-24 h-24 mr-8 rounded-xl"
          srcSet={data.profileImagePaths}
        />
        <p className="text-lg">{data.name}</p>
        <div className="ml-auto">
          <IconArrowRight />
        </div>
      </motion.div>
    </AnimatedTap>
  );
};

export default HamburgerMenuUserCard;
