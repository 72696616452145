import Modal from "../../types/Modal";
import WindowModal, { WindowModalHeader } from "../WindowModal";

import ImageEmail from "../../svg/other/img_email.svg";
import { useTranslation } from "next-i18next";

type EmailSentModalProps = {
  modal: Modal;
};
const EmailSentModal: React.FC<EmailSentModalProps> = ({ modal }) => {
  const { t: translate } = useTranslation("common");
  return (
    <WindowModal>
      <WindowModalHeader modal={modal}>
        <div className="grow text-lg text-center">Email sent</div>
      </WindowModalHeader>
      <div className="flex flex-col items-center space-y-10 pt-2 px-6">
        <ImageEmail className="pt-8 w-24 h-auto" />
        <p className="text-center pb-8 px-9">
          {translate("common:email-sent-modal.check-your-inbox")}
        </p>
      </div>
    </WindowModal>
  );
};

export default EmailSentModal;
