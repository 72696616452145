import useUserControlLinks from "../../../lib/hooks/useUserControlLinks";
import HamburgerMenuLinks from "../HamburgerMenuLinks";

type HamburgerMenuUserControlsPageProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HamburgerMenuUserControlsPage: React.FC<
  HamburgerMenuUserControlsPageProps
> = ({ setIsOpen }) => {
  const links = useUserControlLinks();

  return (
    <HamburgerMenuLinks onLinkClicked={() => setIsOpen(false)} links={links} />
  );
};

export default HamburgerMenuUserControlsPage;
