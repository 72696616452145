import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import apiClient from "../api/apiClient";
import ImageSourceSet from "../types/ImageSourceSet";
import Button from "./Button";
import FilePicker from "./FilePicker";
import FixedRatio from "./FixedRatio";
import LazyLoadedImg from "./LazyLoadedImg";
import { LoadingSpinnerOverlay } from "./LoadingSpinner";

export type ThumbnailPickerProps = {
  standalone?: boolean;
  size?: "full" | "normal";
  initialUrl?: ImageSourceSet;
  value: File | null;
  setValue: (value: File | null) => void;
  className?: string;
};

const ThumbnailPicker: React.FC<ThumbnailPickerProps> = ({
  standalone,
  size = "normal",
  initialUrl,
  value,
  setValue,
  className = "",
}) => {
  const [loadingInitial, setLoadingInitial] = useState(false);
  const { t: translate } = useTranslation("common");
  const [initialValue, setInitialValue] = useState<File | null>(null);

  const [blob, setBlob] = useState<string | null>(null);

  useEffect(() => {
    if (!initialUrl || value) return;
    setLoadingInitial(true);
    const keys = Object.keys(initialUrl);
    apiClient
      .get(initialUrl[keys[keys.length - 1]], { prefixUrl: undefined })
      .blob()
      .then((blob) => {
        setLoadingInitial(false);
        const f = new File([blob], "thumbnail");
        if (!standalone) setValue(f);
        setInitialValue(f);
      });
  }, [initialUrl]);

  const valueDisp = value ?? initialValue;

  useEffect(() => {
    if (valueDisp) setBlob(URL.createObjectURL(valueDisp));

    return () => {
      if (blob) URL.revokeObjectURL(blob);
    };
  }, [valueDisp]);

  return (
    <div>
      <FilePicker onFiles={(files) => setValue(files[0])}>
        {standalone ? (
          <FixedRatio
            ratio={16 / 9}
            className={size === "normal" ? "w-96" : ""}
          >
            <div
              className={`group w-full h-full relative overflow-hidden rounded-2xl bg-gray-700 cursor-pointer hover:bg-opacity-60 transition-colors ${className}`}
            >
              {blob && (
                <LazyLoadedImg className="w-full h-full" srcSet={blob} />
              )}
              <p
                className={`absolute inset-0 flex items-center justify-center ${
                  valueDisp
                    ? "opacity-0 group-hover:opacity-100 bg-black bg-opacity-60 transition-opacity"
                    : ""
                }`}
              >
                {`${
                  valueDisp
                    ? translate("common:thumbnail-picker.change")
                    : translate("common:thumbnail-picker.add")
                } ${translate("common:thumbnail-picker.thumbnail")}`}
              </p>{" "}
              {loadingInitial && <LoadingSpinnerOverlay />}
            </div>
          </FixedRatio>
        ) : (
          <Button variant="secondary" size="full">
            {`${
              valueDisp
                ? translate("common:thumbnail-picker.change")
                : translate("common:thumbnail-picker.add")
            } ${translate("common:thumbnail-picker.thumbnail")}`}
          </Button>
        )}
      </FilePicker>
    </div>
  );
};

export default ThumbnailPicker;
