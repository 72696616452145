export const baseUrl = "https://sportin.live";

export const backendUrl =
  process.env.NEXT_PUBLIC_API_URL || "https://api.sportin.live/";

export const stripePublicKey =
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || "pk_live_51JNEGdGTtAynXT8cHbtWLUJfwyBZus4kjLT5qFh9FJeC4wF21GTmvWTUbygKjagHT9gxTIBKidsdHr2KSeBEBlXR00Mp9sYisq";

export const turnServerUrl = process.env.NEXT_PUBLIC_TURN_URL;
export const turnUsername = process.env.NEXT_PUBLIC_TURN_USERNAME;
export const turnCredential = process.env.NEXT_PUBLIC_TURN_CREDENTIAL;

export const apiPrefixUrl = `${backendUrl}api/`;
export const languages = [
  "Arabic",
  "Bulgarian",
  "Catalan",
  "Chinese",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Slovak",
  "Slovene",
  "Spanish",
  "Swedish",
  "Tagalog",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
];
export const difficulties = ["Beginner", "Medium", "Pro"];
export const currencies = [
  'EUR', 'USD', 'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BIF',
  'BMD', 'BND', 'BOB', 'BRL', 'BSD', 'BWP', 'BYN', 'BZD', 'CAD', 'CDF', 'CHF', 'CLP', 'CNY', 'COP', 'CRC', 'CVE', 'CZK',
  'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ETB', 'FJD', 'FKP', 'GBP', 'GEL', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL',
  'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'INR', 'ISK', 'JMD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KRW', 'KYD', 'KZT', 'LAK',
  'LBP', 'LKR', 'LRD', 'LSL', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR', 'MVR', 'MWK', 'MXN', 'MYR',
  'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD',
  'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SZL', 'THB', 'TJS', 'TOP', 'TRY',
  'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMW'
];

export const streamerTypes = ['Trainer', 'Gym', 'Event', 'Team', 'Association', 'Brand'];

export const themeColor = "#000000";
