import { ReactNode, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

import IconChevronDown from "../svg/ic_chevron_down.svg";
import IconChevronUp from "../svg/ic_chevron_up.svg";

export type ListBoxProps = {
  className?: string;
  error?: string;
  label?: string;
  placeholder?: string;
  items: string[];
  index: number | null | undefined;
  setIndex: (index: number) => void;
  icon?: ReactNode;
  size?: "full" | "min";
  top?: boolean;
  readonly?: boolean;
  onChange?: (index: number) => void;
};

const ListBox: React.FC<ListBoxProps> = ({
  className,
  error,
  label,
  placeholder,
  items,
  index,
  setIndex,
  icon,
  size = "full",
  top = false,
  readonly = false,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const setIndex_ = (index: number) => {
    setIndex(index);
    setIsOpen(false);
    if (onChange) {
      onChange(index);
    }
  };
  // flex rounded-xl items-center ${size == "full" ? "p-4 text-gray-400" : ""} bg-gray-700 gap-4 hover:bg-gray-400 hover:bg-opacity-25 duration-100`
  return (
    <div
      className={`relative flex flex-col ${className} cursor-pointer `}
      ref={ref}
      onClick={() => {
        if (!readonly) setIsOpen(!isOpen);
      }}
    >
      <p className={`mb-2 ${error ? "text-crimson" : "text-gray-400"}`}>
        {error ?? label}
      </p>
      <div
        title={readonly ? "This is field is read only." : ""}
        className={
          readonly
            ? `flex cursor-not-allowed rounded-xl items-center ${
                size == "full" ? "p-4 text-gray-400" : ""
              } bg-gray-800 gap-4 `
            : `flex rounded-xl items-center ${
                size == "full" ? "p-4 text-gray-400" : ""
              } bg-gray-700 gap-4 hover:bg-gray-400 hover:bg-opacity-25 duration-100`
        }
      >
        {icon ?? icon}
        {index === null
          ? placeholder
          : index === undefined
          ? placeholder
          : items[index]}
        {readonly ? (
          <>{/* Read only icon could be added here */}</>
        ) : isOpen ? (
          <IconChevronUp className="ml-auto w-4 h-4" />
        ) : (
          <IconChevronDown className="ml-auto w-4 h-4" />
        )}
      </div>
      <div
        className={`absolute z-1000 right-0 ${
          top ? "bottom-24" : "top-24"
        } w-48 max-h-96 overflow-hidden rounded-xl  ${
          isOpen ? "scale-100" : "scale-95 opacity-0 pointer-events-none"
        } transition duration-100`}
      >
        <ul className="max-h-96 border border-gray-900 bg-gray-700 overflow-auto">
          {items.map((item, i) => (
            <li
              key={`listitem${i}`}
              className="pl-6 py-3 hover:bg-gray-400 hover:bg-opacity-40"
              onClick={() => setIndex_(i)}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ListBox;
