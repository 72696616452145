import SportinSettingsAddCategory from "./SportinSettingsAddCategory";
import SportinSettingsUpdateCategory from "./SportinSettingsUpdateCategory";

const SportinSettings = () => {
  return (
    <div className="space-y-4">
      <h2>Sportin settings</h2>
      <SportinSettingsAddCategory />
      <hr />
      <SportinSettingsUpdateCategory />
    </div>
  );
};

export default SportinSettings;
