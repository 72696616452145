import create from "zustand";
type MobileAppStoreData = {
  isMobileAppView: boolean;
  setIsMobileAppView: (value: boolean) => void;
};

const useMobileAppStore = create<MobileAppStoreData>((set, get) => ({
  isMobileAppView: false,
  setIsMobileAppView: (value: boolean) => set({ isMobileAppView: value }),
}));
export default useMobileAppStore;
