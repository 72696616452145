import { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import Button from "./Button";
import FilePicker from "./FilePicker";
import Input from "./Input";
import ShareLink from "./ShareLink";
import { useCategories } from "../api/hooks";

const SportinSettingsUpdateCategory = () => {
  const [imageId, setImageId] = useState<string>("");
  const [categoryName, setCategoryName] = useState<string>("");
  const [categoryColor, setCategoryColor] = useState<string>("#000000");
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState<string>("");
  const categoriesQuery = useCategories();

  const uploadProfilePicture = async (files: FileList) => {
    const form = new FormData();
    form.append("file", files[0]);
    setIsImageLoading(true);
    const res = (await apiClient
      .post("categories/upload-thumbnail-image", { body: form })
      .json()) as any;
    setImageId(res.imageId as string);
    setIsImageLoading(false);
  };

  const handleCategoryUpdate = async () => {
    const res = await apiClient.patch(`categories/${categoryToUpdate}`, {
      json: {
        name: categoryName,
        backgroundColor: categoryColor,
        textColor: "#ffffff",
        thumbnailImageId: imageId,
      },
    });
    if (res.status === 200) {
      alert("Category updated successfully");
    } else {
      alert("Category update failed");
    }
  };

  const handleCategorySelect = (e: any) => {
    setCategoryToUpdate(e.target.value);
    setImageId("");
  };
  useEffect(() => {
    if (categoryToUpdate !== "") {
      const category = categoriesQuery.data?.find(
        (category) => category.id === categoryToUpdate
      );
      if (category) {
        setCategoryName(category.name);
        setCategoryColor(category.backgroundColor);
      }
    }
  }, [categoryToUpdate, categoriesQuery.data]);
  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-lg">Category update</h3>
      <div className="flex justify-between items-center">
        <label htmlFor="categories" className="w-1/2">
          Select category to update
        </label>
        <select
          className="w-full bg-gray-700 text-white rounded-lg p-5 appearance-none cursor-pointer"
          onChange={handleCategorySelect}
          name="categories"
          id="categories"
        >
          <option value="">Select category</option>
          {categoriesQuery.data?.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name} ({category.viewCount} views) - (ID: {category.id})
            </option>
          ))}
        </select>
      </div>
      {categoryToUpdate !== "" && (
        <div className="flex flex-col gap-4">
          <FilePicker onFiles={uploadProfilePicture}>
            <Button className="w-full" variant="secondary">
              Upload category picture
            </Button>
          </FilePicker>
          {(imageId !== "" || isImageLoading) && (
            <div className="flex items-center">
              <h3 className="w-1/2">Image ID</h3>
              <ShareLink
                link={
                  imageId !== ""
                    ? imageId
                    : isImageLoading
                    ? "Image is currently uploading."
                    : "Not uploaded yet."
                }
              ></ShareLink>
            </div>
          )}
          <div className="flex justify-between items-center">
            <label className="w-1/2" htmlFor="categoryName">
              Category name
            </label>
            <Input
              onChange={(e) => setCategoryName(e.target.value)}
              value={categoryName}
              placeholder="Category name"
            ></Input>
          </div>
          <div className="flex items-center w-full justify-between">
            <label htmlFor="categoryColor">Category color</label>
            <input
              className="w-1/2 rounded-lg"
              name="categoryColor"
              id="categoryColor"
              type="color"
              onChange={(e) => setCategoryColor(e.target.value)}
              value={categoryColor}
            ></input>
          </div>
          <Button onClick={handleCategoryUpdate}>Update Category</Button>
        </div>
      )}
    </div>
  );
};

export default SportinSettingsUpdateCategory;
