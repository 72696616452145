import { useRouter } from "next/router";
import { useSelf } from "../api/hooks";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import apiClient from "../api/apiClient";
import { QueryClient, useQuery } from "react-query";
import AlertContext from "./context/AlertContext";
import { HTTPError } from "ky";
import { getPurchases, getTrainerByName } from "../api/wrapper";
import ModalContext from "./context/ModalContext";
import LoginFormModal from "./modal/LoginFormModal";
import PaymentModal from "./modal/PaymentModal";
import { Purchase } from "../api/entities";
import { cn } from "../lib/cn";

type BuyEventButtonProps = {
  slug: string;
  price: string;
  className?: string;
};

const BuyEventButton: React.FC<BuyEventButtonProps> = ({
  className,
  slug,
  price,
}) => {
  const self = useSelf();
  const router = useRouter();
  const { t: translate } = useTranslation("common");
  const defaultCard = self.data?.cards.find((card) => card.default === true);
  const queryClient = new QueryClient();
  const alert = useContext(AlertContext);
  const isDefaultCardDefined = defaultCard !== undefined;
  const modalContext = useContext(ModalContext);
  const purchases = useQuery("selfPurchases", () => getPurchases());
  const isPurchased = purchases.data?.some(
    (purchase: Purchase) =>
      purchase.trainerSlug === slug && purchase.type === "eventTicketPurchase"
  );
  const trainerDataQuery = useQuery(["trainer", { slug }], () =>
    getTrainerByName(slug)
  );
  const trainerData = trainerDataQuery.data;
  const trainerId = trainerData?.id;
  const handleDefinedDefaultCard = async () => {
    if (defaultCard != undefined) {
      const payApiUrl = `purchases/event-ticket/${trainerId}`;
      try {
        let result;
        if (self.data?.companyName)
          result = await apiClient.post(payApiUrl, {
            json: {
              cardId: defaultCard.id,
              companyReceipt: true,
              returnUrl: window.location.href,
            },
          });
        else
          result = await apiClient.post(payApiUrl, {
            json: {
              cardId: defaultCard.id,
              returnUrl: window.location.href,
            },
          });

        const resultJson = await result.json();
        if (resultJson.redirectUrl) {
          window.location.href = resultJson.redirectUrl;
          return;
        }

        if (resultJson.error) {
          alert?.error(`Payment failed: ${resultJson.error.message}`);
          return;
        }

        setTimeout(async () => {
          alert?.success("Succesfully purchased event ticket!");
          await queryClient.invalidateQueries("selfPurchases");
          trainerDataQuery.refetch();
        }, 3000);
      } catch (err) {
        if (err instanceof HTTPError) {
          const response = JSON.parse(await err.response.text()).details;
          alert?.error("Payment failed: " + response);
        }
      }
    } else {
      if (self.data == undefined) {
        modalContext.show(LoginFormModal, {
          normalSignup: true,
          trainerId,
        });
      } else {
        alert?.error(
          translate("common:videos.you-need-to-add-a-credit-card-first")
        );
        router.push("/dashboard");
      }
    }
  };
  if (isPurchased)
    return (
      <Button className={className} disabled>
        {translate("common:videos.purchased")}
      </Button>
    );
  return (
    <>
      {isDefaultCardDefined && (
        <Button
          size="large"
          variant="cart"
          className={cn(
            "sm:mx-auto ml-auto hover:text-black flex flex-col items-center",
            className
          )}
          awaitOnClick={handleDefinedDefaultCard}
        >
          <div className="flex flex-col text-lg items-center w-48">
            <span className="text-[1.2rem]">
              {translate("common:buy-event-for")}
            </span>
            <span className="text-normal">
              {translate("common:price-display", {
                priceAndCurrency: price,
              })}
            </span>
          </div>
        </Button>
      )}

      {trainerData && !isDefaultCardDefined && (
        <Button
          variant="cart"
          className={cn("w-auto px-5", className)}
          onClick={() => {
            if (self.data == undefined) {
              modalContext.show(LoginFormModal, {
                normalSignup: true,
                trainerId: trainerData.id,
              });
              return;
            } else {
              modalContext.show(PaymentModal, {
                type: "eventTicketPurchase",
                itemId: trainerData.id,
                stripeAccount: trainerData.stripeAccountId,
              });
            }
          }}
        >
          <div className="flex flex-col text-lg items-center w-48">
            <span className="text-[1.2rem]">
              {translate("common:buy-event-for")}
            </span>
            <span className="text-normal">
              {translate("common:price-display", {
                priceAndCurrency: price,
              })}
            </span>
          </div>
        </Button>
      )}
    </>
  );
};

export default BuyEventButton;
