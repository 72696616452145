import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Modal from "../types/Modal";
import Button from "../components/Button";
import React, { FormEvent, useContext, useState } from "react";
import AlertContext from "./context/AlertContext";

const StripePaymentForm: React.FC<any> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const alert = useContext(AlertContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    setIsSubmitting(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (result.error) {
      setIsSubmitting(false);
      // Show error to your customer (for example, payment details incomplete)
      alert?.error(`Payment failed: ${result.error.message}`);
    } else {
      setTimeout(() => {
        alert?.success("Payment succeeded.");
      }, 7000);

      setTimeout(() => {
        window.location.reload();
      }, 9000);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Submit your payment</h2>
      <br />
      <PaymentElement />
      <div
        style={{
          margin: "20px 0",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button type={"submit"} disabled={isSubmitting} loading={isSubmitting}>
          Submit
        </Button>
      </div>
      <img src="/stripe-badge-grey.png" alt="Stripe badge" />
    </form>
  );
};

export default StripePaymentForm;
