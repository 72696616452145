import { Options } from "ky";
import apiClient from "./apiClient";
import {
  AccountConnection,
  Audio,
  Category,
  ChatMessage,
  ConnectableAccounts,
  Course,
  Earnings,
  Home,
  HomeAllTrainers,
  HomeCategory,
  HomeComingUp,
  HomeTrending,
  PaymentAccount,
  PaymentIntent,
  Purchase,
  Stream,
  StreamerTypes,
  StreamPublishDetails,
  Subscription,
  Trainer,
  User,
  Video,
} from "./entities";

function api<TResponse>(method: string, url: string): Promise<TResponse>;
function api<TResponse, TBody>(
  method: string,
  url: string,
  json?: TBody,
  modifier?: (x: any) => any
): Promise<TResponse>;

function api<TResponse, TBody>(
  method: string,
  url: string,
  json?: TBody,
  modifier?: (x: any) => any
): Promise<TResponse> {
  const options: Options = { method: method };
  if (json) options.json = json;

  return apiClient(url, options).then((res) => {
    if (!res.ok) throw new Error(res.statusText);
    return modifier ? res.json().then(modifier) : res.json();
  });
}

export const getSelf = () => api<User>("GET", "users/profile");
export const getCategories = () =>
  api<Category[]>(
    "GET",
    `categories?filter=${JSON.stringify({
      order: "name ASC",
    })}`
  );
export const getCategoryByID = (id: string) =>
  api<Category>("GET", `categories/${id}`);
export const getTrainerByID = (id: string) =>
  api<Trainer>("GET", `trainers/${id}`);
export const getVideoByID = (id: string) => api<Video>("GET", `videos/${id}`);
export const getVideoPaymentIntentByID = (id: string) =>
  api<PaymentIntent>("GET", `purchases/payment-intent/video/${id}`);
export const getOwnVideos = () =>
  api<Video[]>(
    "GET",
    `users/videos?filter=${JSON.stringify({
      order: "createdAt DESC",
    })}`
  );
export const getOwnVideosByCourseId = (id: string) =>
  api<Video[]>(
    "GET",
    `users/videos?filter=${JSON.stringify({
      where: { courseId: id },
      order: "createdAt DESC",
    })}`
  );
export const getOwnCourses = () =>
  api<Course[]>(
    "GET",
    `users/courses?filter=${JSON.stringify({
      order: "createdAt DESC",
    })}`
  );
export const getStreamPublishDetails = (id: string) => {
  return api<StreamPublishDetails>("GET", `streams/${id}/play-details`);
};
export const getTrainerVideosByID = (id: string) =>
  api<Video[]>(
    "GET",
    `videos?filter=${JSON.stringify({
      where: {
        userId: id,
        or: [{ courseId: null }, { courseId: { exists: false } }],
      },
      order: "createdAt DESC",
    })}`
  );
export const getStreamByID = (id: string) =>
  api<Stream>("GET", `streams/${id}`);
export const getTrainersLike = (query: string) =>
  api<User[]>(
    "GET",
    `trainers?filter=${JSON.stringify({
      where: {
        name: { like: `${query}`, options: "i" },
      },
    })}`
  );
export const getTrainerByName = (text: string) =>
  api<Trainer, void>(
    "GET",
    `trainers?filter=${JSON.stringify({
      where: {
        slug: text,
      },
    })}`,
    undefined,
    (x: Trainer[]) => x[0]
  );
export const getVideosByCourseID = (id: string) =>
  api<Video[]>(
    "GET",
    `videos?filter=${JSON.stringify({
      where: {
        courseId: id,
      },
    })}`
  );
export const getCourseByID = (id: string) =>
  api<Course>("GET", `courses/${id}`);
export const getCoursesByUserID = (id: string) =>
  api<Course[]>(
    "GET",
    `courses?filter=${JSON.stringify({
      where: {
        userId: id,
      },
    })}`
  );
export const getPreviousChatMessages = (streamId: string) =>
  api<ChatMessage[]>(
    "GET",
    `chat-messages/${streamId}?filter=${JSON.stringify({
      limit: 50,
      order: "createdAt DESC",
    })}`
  );
export const getStreamsByUserID = (id: string) =>
  api<Stream[]>(
    "GET",
    `streams?filter=${JSON.stringify({
      order: "plannedStartDate ASC",
      where: {
        userId: id,
      },
    })}`
  );
export const getUpcomingStreamsByUserID = (id: string) =>
  api<Stream[]>(
    "GET",
    `streams?filter=${JSON.stringify({
      order: "plannedStartDate ASC",
      where: {
        userId: id,
        or: [{ status: "pending" }, { status: "live" }],
        plannedStartDate: {
          gt: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
        },
      },
    })}`
  );
export const getHomepageData = () => api<Home>("GET", "homepage-data");
export const getRecommendedStreams = () =>
  api<Stream[]>("GET", "recommended-streams");
export const getRecommendedCourses = () =>
  api<Course[]>("GET", "recommended-courses");
export const getStreamerTypes = () =>
  api<StreamerTypes>("GET", "streamer-types");
export const getPurchases = () => api<Purchase[]>("GET", "purchases");
export const getSubscriptionToTrainer = (id: string) =>
  api<Subscription, void>(
    "GET",
    `subscriptions?filter=${JSON.stringify({
      where: {
        trainerId: id,
        active: true,
      },
    })}`,
    undefined,
    (x: Subscription[]) => x[0]
  );
export const getUserFollows = (id: string) =>
  api<Trainer[]>(
    "GET",
    `trainers?filter=${JSON.stringify({
      where: {
        followers: id,
      },
    })}`
  );
export const getEarnings = () => api<Earnings>("GET", "trainers/balance");
export const getCourses = () => api<Course[]>("GET", "courses");
export const getPaymentAccountLink = () =>
  api<PaymentAccount>("GET", "trainers/payment-account-link");
export const getEventTicketSales = () =>
  api<any>("GET", "trainers/event-ticket-sales");
export const getHomeCategoryData = (id: string) =>
  api<HomeCategory>("GET", `page/category/${id}`);
export const getHomeTrending = () => api<HomeTrending>("GET", "page/trending");
export const getHomeComingUp = (categories: string[]) =>
  api<HomeComingUp>(
    "GET",
    `page/coming-up${
      categories.length > 0
        ? `?categoryIds=${categories
            .map((category, i) => {
              if (i == categories.length - 1) return category;
              else return category + "&categoryIds=";
            })
            .join("")}`
        : ""
    }`
  );
export const getHomeAllTrainers = (categories: string[]) =>
  api<HomeAllTrainers>(
    "GET",
    `page/all-trainers${
      categories.length > 0
        ? `?categoryIds=${categories
            .map((category, i) => {
              if (i == categories.length - 1) return category;
              else return category + "&categoryIds=";
            })
            .join("")}`
        : ""
    }`
  );
export const getAudios = () => api<Audio[]>("GET", "audios");
export const getAccountConnects = () =>
  api<AccountConnection>("GET", "account-connect");
export const getConnectableAccounts = () =>
  api<ConnectableAccounts>("GET", "account-connect/connectable-accounts");

export const getHomeLatestVideos = (limit: number = 20) =>
  api<Video[]>(
    "GET",
    `videos?filter=${JSON.stringify({
      order: "createdAt DESC",
      limit: limit,
    })}`
  );

export const getLatestVideosByCategory = (
  categoryId: string,
  limit: number = 20
) =>
  api<Video[]>(
    "GET",
    `videos?filter=${JSON.stringify({
      order: "createdAt DESC",
      limit: limit,
      where: {
        categoryId: {
          eq: categoryId,
        },
      },
    })}`
  );

export const getVideosByTagsAndUser = (
  tags: string[],
  userId?: string,
  limit: number = 20
) => {
  const whereClause: any = {
    tags: {
      inq: tags,
    },
  };

  if (userId) {
    whereClause.userId = userId;
  }

  return api<Video[]>(
    "GET",
    `videos?filter=${JSON.stringify({
      order: "createdAt ASC",
      limit: limit,
      where: whereClause,
    })}`
  );
};
