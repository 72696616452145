import countries from "countries-list";
import { Form, Formik, FormikProps } from "formik";
import { HTTPError } from "ky";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";

import apiClient from "../../api/apiClient";
import { CardDetails } from "../../api/entities";
import { useSelf } from "../../api/hooks";
import { getTrainerByID } from "../../api/wrapper";

import Button from "../../components/Button";
import AlertContext from "../../components/context/AlertContext";
import ModalContext from "../../components/context/ModalContext";
import FilePicker from "../../components/FilePicker";
import Input from "../../components/Input";
import InputField from "../../components/InputField";
import MainLayout from "../../components/layouts/MainLayout";
import LazyLoadedImg from "../../components/LazyLoadedImg";
import ListSearchField from "../../components/ListSearchField";
import EmailSentModal from "../../components/modal/EmailSentModal";
import RadioButtonField from "../../components/RadioButtonField";
import useUserStore from "../../lib/hooks/useUserStore";
import CustomNextPage from "../../types/CustomNextPage";
import ImageSourceSet from "../../types/ImageSourceSet";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import ToggleButton from "../../components/ToggleButton";
import CardElementWrapper from "../../components/card/CardElementWrapper";
import { useTranslation } from "next-i18next";
import SportinSettings from "../../components/SportinSettings";

type CardInput = {
  nameInput?: HTMLInputElement;
  cardInput?: HTMLInputElement;
  expiryMonthInput?: HTMLInputElement;
  expiryYearInput?: HTMLInputElement;
  cvcInput?: HTMLInputElement;
};

const Dashboard: CustomNextPage = () => {
  const queryClient = useQueryClient();
  const { t: translate } = useTranslation("common");
  const taxSubjects = ["person", "company"];
  const countryCodes = Object.keys(countries.countries);
  const countryNames = Object.values(countries.countries).map(
    (item) => item.name
  );

  const Schema = Yup.object().shape({
    name: Yup.string().required(
      translate("common:dashboard-index.a-name-is-required") ??
        "A name is required."
    ),
  });
  const Schema2 = Yup.object().shape({
    email: Yup.string().required(
      translate("common:dashboard-index.an-email-is-required") ??
        "An email is required."
    ),
  });
  const Schema3 = Yup.object().shape({
    password: Yup.string().required(
      translate("common:dashboard-index.your-old-password-is-required") ??
        "Your old password is required."
    ),
    newpassword: Yup.string().required(
      translate("common:dashboard-index.your-new-password-is-required") ??
        "A new password is required."
    ),
    newpasswordagain: Yup.string().required(
      translate("common:dashboard-index.a-new-password-is-required") ??
        "A new password is required."
    ),
  });
  const Schema4 = Yup.object().shape({
    city: Yup.string().required(
      translate("common:dashboard-index.a-city-is-required") ??
        "A city is required."
    ),
    zip: Yup.string().required(
      translate("common:dashboard-index.a-zip-code-is-required") ??
        "A zip code is required."
    ),
    address: Yup.string().required(
      translate("common:dashboard-index.an-address-is-required") ??
        "An address is required."
    ),
    taxNumber: Yup.string().when("taxSubject", {
      is: 1,
      then: (schema) =>
        schema.required(
          translate("common:dashboard-index.a-tax-number-is-required") ??
            "A tax number is required."
        ),
    }),
    companyName: Yup.string().when("taxSubject", {
      is: 1,
      then: (schema) =>
        schema.required(
          translate("common:dashboard-index.a-company-name-is-required") ??
            "A company name is required."
        ),
    }),
    state: Yup.string()
      .required(
        translate("common:dashboard-index.a-state-is-required") ??
          "A state is required."
      )
      .nullable(),
    taxSubject: Yup.number(),
  });

  const alert = useContext(AlertContext);
  const modalContext = useContext(ModalContext);

  const invoiceRef = useRef<FormikProps<any> | null>(null);
  const profileRef = useRef<FormikProps<any> | null>(null);

  const self = useSelf();
  const trainer = useQuery(
    ["trainerid", self.data?.trainerIdRegisteredFrom],
    () => getTrainerByID(self.data!.trainerIdRegisteredFrom),
    { enabled: !!self.data?.trainerIdRegisteredFrom }
  );

  const [cards, setCards] = useState<CardDetails[]>([]);
  const [addCard, setAddCard] = useState<boolean>(false);
  const inputRefs = useRef<CardInput[]>([]);

  const router = useRouter();

  const [profileImageUrl, setProfileImageUrl] = useState<
    ImageSourceSet | string
  >("/profile.svg");

  useEffect(() => {
    if (self.data) {
      if (self.data.profileImagePaths)
        setProfileImageUrl(self.data.profileImagePaths);

      if (cards.length == 0 && self.data.cards) {
        self.data.cards.map((card: CardDetails) => {
          setCards((cards) => [
            ...cards,
            {
              id: card.id,
              number: card.number,
              expMonth: card.expMonth,
              last4: card.last4,
              expYear: card.expYear,
              cvc: "***",
              name: card.name,
              default: card.default,
            },
          ]);
        });
      }
    }
  }, [self.data]);

  useEffect(() => {
    cards.map((card, i) => {
      const input = inputRefs.current[i];
      if (cards[i].id) {
        if (input.nameInput) {
          input.nameInput.value = card.name;
          input.nameInput.disabled = true;
        }

        if (input.cardInput) {
          input.cardInput.value = card.last4;
          input.cardInput.disabled = true;
        }

        if (input.expiryMonthInput) {
          input.expiryMonthInput.value = card.expMonth;
          input.expiryMonthInput.disabled = true;
        }

        if (input.expiryYearInput) {
          input.expiryYearInput.value = card.expYear;
          input.expiryYearInput.disabled = true;
        }

        if (input.cvcInput) {
          input.cvcInput.value = card.cvc;
          input.cvcInput.disabled = true;
        }
      }
    });
  }, [cards.length]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, cards.length);
  }, [cards]);

  const uploadProfilePicture = async (files: FileList) => {
    const form = new FormData();
    form.append("file", files[0]);

    setProfileImageUrl(
      (
        (await apiClient
          .post("users/upload-profile-image", { body: form })
          .json()) as any
      ).urls
    );
    self.refetch();
  };

  const handleResult = (data: any) => {
    if (data.result === "success") {
      self.refetch();
      setCards([]);
      setAddCard(false);
      alert?.success(translate("common:dashboard-index.card-details-saved"));
    } else {
      alert?.error(
        translate("common:dashboard-index.error-during-saving-card"),
        data.message
      );
    }
  };

  const HandleSubmit = async () => {
    if (profileRef.current && invoiceRef.current) {
      await profileRef.current.validateForm();
      await invoiceRef.current.validateForm();
      if (profileRef.current.isValid && invoiceRef.current.isValid) {
        profileRef.current.handleSubmit();
        invoiceRef.current.handleSubmit();
      }
    }
  };
  if (!self.data) return null;
  const isAddressSet =
    !!self.data.address && !!self.data.city && !!self.data.zip;

  return (
    <div className="mx-8 mt-20 p-5">
      <div className="md:absolute z-50 left-10 flex-col space-y-4 justify-center">
        <div className="flex-col space-y-4 justify-center">
          <div className="flex flex-col space-y-4 items-center">
            <LazyLoadedImg
              className="w-60 h-60 rounded-xl"
              srcSet={profileImageUrl}
            />
            <FilePicker onFiles={uploadProfilePicture}>
              <Button variant="text">
                {profileImageUrl
                  ? translate("common:dashboard-index.edit-profile-picture")
                  : translate("common:dashboard-index.upload-profile-picture")}
              </Button>
            </FilePicker>
          </div>
        </div>
      </div>
      <div className="flex gap-16 justify-center w-full">
        <div className="flex flex-col space-y-8 w-full max-w-3xl">
          <Formik
            innerRef={profileRef}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Schema}
            initialValues={{
              name: self.data.name ?? "",
            }}
            onSubmit={async (values) => {
              try {
                await apiClient.patch("users/update-profile", {
                  json: { name: values.name },
                });
                if (!self.data.profileFilled) {
                  await apiClient.put("users/set-profile-filled", { json: {} });
                  await queryClient.resetQueries("self");
                  // await router.push(`/profiles/${trainer.data?.slug}`);
                }
                alert?.success(
                  translate(
                    "common:dashboard-index.saved-changes-successfully"
                  ) ?? "Saved changes successfully."
                );
              } catch (err) {
                if (err instanceof HTTPError)
                  alert?.error(
                    translate(
                      "common:dashboard-index.failed-to-save-changes"
                    ) ?? "Failed to save changes. " + err.response
                  );
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex">
                  <div className="space-y-4 w-full max-w-3xl">
                    <h2>
                      {translate("common:dashboard-index.profile-settings")}
                    </h2>
                    <InputField
                      name="name"
                      label={translate("common:dashboard-index.name") ?? "Name"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <Formik
            innerRef={invoiceRef}
            validateOnChange={false}
            onSubmit={async (values) => {
              let reqBody: any = {
                country: countryCodes[values.country],
                city: values.city,
                zip: values.zip,
                address: values.address,
                taxSubject: taxSubjects[values.taxSubject],
                state: values.state,
              };
              if (values.taxSubject === 1)
                reqBody = {
                  ...reqBody,
                  taxNumber: values.taxNumber,
                  companyName: values.companyName,
                };

              apiClient.patch("users/update-profile", {
                json: reqBody,
              });
            }}
            validateOnBlur={false}
            validationSchema={Schema4}
            initialValues={{
              country: self.data.country
                ? countryCodes.indexOf(self.data.country)
                : countryCodes.indexOf("HU") ?? "",
              city: self.data.city ?? "",
              zip: self.data.zip ?? "",
              address: self.data.address ?? "",
              taxNumber: self.data.taxNumber ?? "",
              taxSubject: self.data.taxSubject === "company" ? 1 : 0,
              companyName: self.data.companyName ?? "",
              state: self.data.state,
            }}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className="space-y-4">
                  <h2>{translate("common:dashboard-index.invoice")}</h2>
                  <div className="flex gap-5 items-center">
                    <RadioButtonField
                      name="taxSubject"
                      buttons={[
                        translate("common:dashboard-index.person"),
                        translate("common:dashboard-index.company"),
                      ]}
                    />
                    {!!values.taxSubject && (
                      <InputField
                        placeholder={
                          translate(
                            "common:dashboard-index.add-company-name"
                          ) ?? "Add company name"
                        }
                        className="flex-1"
                        name="companyName"
                        label={
                          translate("common:dashboard-index.company-name") ??
                          "Company name"
                        }
                      />
                    )}
                  </div>

                  <div className="flex gap-x-10">
                    <ListSearchField
                      items={countryNames}
                      name="country"
                      label={
                        translate("common:dashboard-index.country") ?? "Country"
                      }
                    />
                    <InputField
                      placeholder={
                        translate("common:dashboard-index.add-city") ??
                        "Add city"
                      }
                      className="flex-1"
                      name="city"
                      label={translate("common:dashboard-index.city") ?? "City"}
                    />
                  </div>
                  <InputField
                    placeholder={
                      translate(
                        "common:dashboard-index.street-level-apartment-number"
                      ) ?? "Street, level, apartment/number"
                    }
                    name="address"
                    label={
                      translate("common:dashboard-index.address") ?? "Address"
                    }
                  />
                  <InputField
                    placeholder={
                      translate(
                        "common:dashboard-index.add-state-or-district"
                      ) ?? "Add state or district"
                    }
                    className="flex-1"
                    name="state"
                    label={
                      translate("common:dashboard-index.state-or-district") ??
                      "State or district"
                    }
                  />
                  <div className="flex gap-x-5">
                    <InputField
                      placeholder={translate("common:zip-code") ?? "Zip Code"}
                      className="flex-1"
                      name="zip"
                      label={
                        translate("common:dashboard-index.zip-code") ??
                        "Zip Code"
                      }
                    />
                    {values.taxSubject ? (
                      <InputField
                        placeholder={
                          translate("common:dashboard-index.add-vat-number") ??
                          "Add VAT number"
                        }
                        className="flex-1"
                        name="taxNumber"
                        label={
                          translate("common:dashboard-index.vat") + "*" ??
                          "VAT*"
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {!!values.taxSubject && (
                    <p className="text-sm  text-gray-400 ml-auto w-52">
                      *{translate("common:dashboard-index.please-use-eu-vat")}
                    </p>
                  )}
                </div>
                <Button
                  className="mt-5"
                  loading={isSubmitting}
                  onClick={() => HandleSubmit()}
                >
                  {translate("common:dashboard-index.save-profile")}
                </Button>
              </Form>
            )}
          </Formik>

          <div className="space-y-4">
            <h2>{translate("common:dashboard-index.payment-methods")}</h2>
            <div className="space-y-4">
              {cards.map((card, i) => {
                inputRefs.current[i] = {};
                return (
                  <div key={i} className="flex gap-4 items-center flex-wrap">
                    <Input
                      label={
                        translate("common:dashboard-index.name-on-card") ??
                        "Name on card"
                      }
                      ref={(e) => {
                        const input = inputRefs.current[i];
                        if (input && e) input.nameInput = e;
                      }}
                    />
                    <Input
                      label={
                        translate("common:dashboard-index.card-number") ??
                        "Card number"
                      }
                      maxLength={16}
                      ref={(e) => {
                        const input = inputRefs.current[i];
                        if (input && e) input.cardInput = e;
                      }}
                    />

                    <div>
                      <p className="text-gray-400 mb-2">
                        {translate("common:dashboard-index.expiration-date")}
                      </p>
                      <div className="flex rounded-2xl bg-gray-700 justify-center items-center">
                        <Input
                          placeholder={
                            translate(
                              "common:dashboard-index.month-placeholder"
                            ) ?? "MM"
                          }
                          maxLength={2}
                          innerClassName="w-16 text-center ring-opacity-0"
                          onChange={(e) => {
                            const input = inputRefs.current[i];
                            if (e.target.value.length == 2)
                              input.expiryYearInput?.focus();
                          }}
                          ref={(e) => {
                            const input = inputRefs.current[i];
                            if (input && e) input.expiryMonthInput = e;
                          }}
                        />
                        <div className=" text-lg">/</div>
                        <Input
                          placeholder={
                            translate(
                              "common:dashboard-index.year-placeholder"
                            ) ?? "YY"
                          }
                          maxLength={4}
                          onKeyDown={(e) => {
                            const key = e.keyCode || e.charCode;
                            const input = inputRefs.current[i];
                            if (
                              key == 8 &&
                              input.expiryYearInput?.value.length == 0
                            )
                              input.expiryMonthInput?.focus();
                          }}
                          innerClassName="w-20 text-center ring-opacity-0"
                          ref={(e) => {
                            const input = inputRefs.current[i];
                            if (input && e) input.expiryYearInput = e;
                          }}
                        />
                      </div>
                    </div>
                    <Input
                      label={translate("common:dashboard-index.cvc") ?? "CVC"}
                      innerClassName="w-16 text-center"
                      maxLength={3}
                      ref={(e) => {
                        const input = inputRefs.current[i];
                        if (input && e) input.cvcInput = e;
                      }}
                    />
                    {!cards[i].id && (
                      <Button
                        awaitOnClick={async () => {
                          const input = inputRefs.current[i];
                          try {
                            await apiClient.post("purchases/create-card", {
                              json: {
                                number: input.cardInput?.value,
                                expMonth: input.expiryMonthInput?.value,
                                expYear: input.expiryYearInput?.value,
                                cvc: input.cvcInput?.value,
                                name: input.nameInput?.value,
                              },
                            });
                            alert?.success(
                              translate(
                                "common:dashboard-index.card-details-saved"
                              )
                            );
                            setCards([]);
                            self.refetch();
                          } catch (err) {
                            if (err instanceof HTTPError) {
                              const response = JSON.parse(
                                await err.response.text()
                              );
                              alert?.error(
                                `${translate(
                                  "common:dashboard-index.failed-to-save-card-details"
                                )} ${response ? `(${response.details})` : ""}`
                              );
                            }
                          }
                        }}
                        variant="text"
                        size="min"
                      >
                        {translate("common:dashboard-index.save-changes")}
                      </Button>
                    )}
                    {card.id && !card.default && (
                      <Button
                        variant="text"
                        size="min"
                        onClick={async () => {
                          apiClient
                            .post(`purchases/set-default-card/${card.id}`)
                            .then(() => {
                              alert?.success(
                                translate(
                                  "common:dashboard-index.card-was-set-as-default"
                                )
                              );
                              self.refetch();
                              setCards([]);
                            });
                        }}
                      >
                        {translate("common:dashboard-index.set-as-default")}
                      </Button>
                    )}
                    <Button
                      variant="text"
                      size="min"
                      onClick={async () => {
                        if (cards[i].id) {
                          apiClient
                            .delete(`purchases/delete-card/${cards[i].id}`)
                            .then(() => {
                              setCards((cards) =>
                                cards.filter((_, j) => j !== i)
                              );
                            });
                        } else {
                          setCards((cards) => cards.filter((_, j) => j !== i));
                        }
                        const input = inputRefs.current[i];
                        if (input.nameInput) {
                          input.nameInput.value = "";
                          input.nameInput.disabled = false;
                        }

                        if (input.cardInput) {
                          input.cardInput.value = "";
                          input.cardInput.disabled = false;
                        }

                        if (input.expiryMonthInput) {
                          input.expiryMonthInput.value = "";
                          input.expiryMonthInput.disabled = false;
                        }

                        if (input.expiryYearInput) {
                          input.expiryYearInput.value = "";
                          input.expiryYearInput.disabled = false;
                        }

                        if (input.cvcInput) {
                          input.cvcInput.value = "";
                          input.cvcInput.disabled = false;
                        }
                      }}
                    >
                      {translate("common:dashboard-index.remove-card")}
                    </Button>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-4 items-center">
              {!addCard && (
                <Button
                  onClick={() => {
                    setAddCard(true);
                  }}
                  disabled={!isAddressSet}
                >
                  {translate("common:dashboard-index.add-a-new-card")}
                </Button>
              )}
              {!isAddressSet && (
                <div className="text-gray-400">
                  Kártya hozzáadásához előbb mentsd el a számlázási adataidat!
                </div>
              )}
            </div>
            {addCard && (
              <CardElementWrapper
                resultFunction={handleResult}
                customerId={self.data.stripeAccountId}
              ></CardElementWrapper>
            )}
          </div>
          <div className="space-y-4">
            <div className="flex gap-5">
              <h2>{translate("common:dashboard-index.account-settings")}</h2>
              <Button
                className="ml-auto"
                size="min"
                variant="text"
                onClick={() =>
                  modalContext.show(ConfirmationModal, {
                    action: async () => {
                      try {
                        await apiClient.delete("users");
                        alert?.success(
                          translate("common:dashboard-index.account-deleted") ??
                            "Account deleted successfully."
                        );
                        useUserStore.getState().setAccessToken(null);
                        await router.push("/home");
                      } catch (err) {
                        alert?.error(
                          `${translate(
                            "common:dashboard-index.failed-to-delete-account"
                          )} ${
                            err instanceof HTTPError
                              ? `(${(await err.response.json()).error.message})`
                              : ""
                          }`
                        );
                      }
                    },
                    title: translate(
                      "common:dashboard-index.are-you-sure-delete"
                    ),
                    description:
                      translate(
                        "common:dashboard-index.after-clicking-delete-profile"
                      ) ??
                      "After clicking delete profile, your profile and all your content will be permanently deleted and cannot be restored.",
                    confirmButtonLabel: translate(
                      "common:dashboard-index.delete-profile"
                    ),
                  })
                }
              >
                {translate("common:dashboard-index.delete-account")}
              </Button>
            </div>
            {/*<Button
              size="min"
              awaitOnClick={async () => {
                try {
                  await apiClient.patch("trainers/add-trainer-role");
                  alert?.success("Account successfully converted to trainer.");
                  useUserStore.getState().refreshLogin();
                } catch (err) {
                  alert?.error("Failed to convert account to trainer.");
                }
              }}
            >
              Convert to trainer
            </Button>*/}
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Schema2}
              initialValues={{
                email: self.data.email ?? "",
              }}
              onSubmit={async (values) => {
                try {
                  await apiClient.post("change-email/init", {
                    json: { email: values.email },
                  });
                  modalContext.show(EmailSentModal, {});
                } catch (err) {
                  alert?.error(
                    `${"common:dashboard-index.failed-to-send-email"} ${
                      err instanceof HTTPError
                        ? `(${(await err.response.json()).error.message})`
                        : ""
                    }`
                  );
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="space-y-4">
                    <InputField
                      name="email"
                      label={
                        translate("common:dashboard-index.email") ?? "Email"
                      }
                    />
                    <Button size="min" loading={isSubmitting} type="submit">
                      {translate("common:dashboard-index.change-email")}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Schema3}
              initialValues={{
                password: "",
                newpassword: "",
                newpasswordagain: "",
              }}
              onSubmit={async (values) => {
                try {
                  await apiClient.put("change-password", {
                    json: {
                      currentPassword: values.password,
                      newPassword: values.newpassword,
                      newPasswordConfirm: values.newpasswordagain,
                    },
                  });
                  alert?.success(
                    translate(
                      "common:dashboard-index.password-changed-successfully"
                    )
                  );
                } catch (err) {
                  alert?.error(
                    `${translate(
                      "common:dashboard-index.failed-to-change-password"
                    )} ${
                      err instanceof HTTPError
                        ? `(${(await err.response.json()).error.message})`
                        : ""
                    }`
                  );
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="space-y-4">
                    <InputField
                      name="password"
                      label={
                        translate("common:dashboard-index.password") ??
                        "Password"
                      }
                      placeholder={
                        translate("common:dashboard-index.current-password") ??
                        "Current password"
                      }
                      type="password"
                    />
                    <InputField
                      name="newpassword"
                      placeholder={
                        translate("common:dashboard-index.new-password") ??
                        "New password"
                      }
                      type="password"
                    />
                    <InputField
                      name="newpasswordagain"
                      placeholder={
                        translate("common:dashboard-index.confirm-password") ??
                        "Confirm password"
                      }
                      type="password"
                    />
                    <Button size="min" loading={isSubmitting} type="submit">
                      {translate("common:dashboard-index.change-password")}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="space-y-4">
            <div className="flex gap-5">
              <h2>
                {translate("common:dashboard-index.notification-settings")}
              </h2>
            </div>
            <div className="flex gap-5">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td style={{ border: "none" }}>
                      {translate(
                        "common:dashboard-index.receive-notification-desc"
                      )}
                    </td>
                    <td style={{ border: "none" }}>
                      <ToggleButton
                        initialState={self.data.sendStreamNotificationMail}
                        onClick={(state) => {
                          apiClient.patch(`users/update-profile`, {
                            json: { sendStreamNotificationMail: state },
                          });
                          queryClient.invalidateQueries(["self"]);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "none", paddingTop: 0 }}>
                      {translate(
                        "common:dashboard-index.receive-daily-notification-desc"
                      )}
                    </td>
                    <td style={{ border: "none", paddingTop: 0 }}>
                      <ToggleButton
                        initialState={self.data.sendDailyNotifierMail}
                        onClick={(state) => {
                          apiClient.patch(`users/update-profile`, {
                            json: { sendDailyNotifierMail: state },
                          });
                          queryClient.invalidateQueries(["self"]);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {self.data.email === "admin@fitters.live" && <SportinSettings />}
        </div>
      </div>
    </div>
  );
};
Dashboard.layout = MainLayout;
Dashboard.guard = "logged-in";
export default Dashboard;
