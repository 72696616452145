import React from "react";
import { useField } from "formik";

import MultiSelectListBox, {
  MultiSelectListBoxProps,
} from "./MultiSelectListBox";

const MultiSelectListBoxField: React.FC<
  Omit<
    MultiSelectListBoxProps & {
      name: string;
    },
    "selectedIndices" | "setSelectedIndices"
  >
> = (props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <MultiSelectListBox
      error={meta.error}
      selectedIndices={meta.value ?? []}
      setSelectedIndices={(v: number[]) => helpers.setValue(v)}
      {...field}
      {...props}
    />
  );
};

export default MultiSelectListBoxField;
