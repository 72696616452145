import { useEffect, useRef, useState } from "react";
import countries from "countries-list";
import { useTranslation } from "next-i18next";
import useOnclickOutside from "react-cool-onclickoutside";

export type ListSearchProps = {
  items: string[];
  error?: string;
  index: number;
  label: string;
  setIndex: (index: number) => void;
};

const ListSearch: React.FC<ListSearchProps> = ({
  items,
  label,
  index,
  error,
  setIndex,
}) => {
  const { t: translate } = useTranslation("common");
  const countryCodes = Object.keys(countries.countries);
  const countryNames = Object.values(countries.countries).map(
    (item) => item.name
  );
  const refClickOutside = useOnclickOutside(() => {
    setInputVisible(false);
    if (!countryNames.includes(inputState)) {
      setInputState(
        translate("common:dashboard.select-a-country") ?? "Select a country"
      );
    }
  });
  const [inputState, setInputState] = useState(
    translate("common:dashboard.select-a-country") ?? "Select a country"
  );
  const [inputVisible, setInputVisible] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    setInputState(items[index]);
  }, [index]);
  const ring = error ? "ring-1 ring-crimson" : "focus:ring-1 ring-primary";
  return (
    <div ref={refClickOutside} className="relative">
      {label && <p className="text-gray-400 mb-2">{label}</p>}
      {inputVisible ? (
        <input
          ref={ref}
          type="text"
          className="w-full bg-gray-700 p-4 rounded-2xl"
          onChange={(e) => {
            setInputState(e.currentTarget.value);
          }}
          value={inputState}
        />
      ) : (
        <div
          className={`flex items-center p-3 gap-4 bg-gray-700 ${ring} rounded-2xl hover:bg-gray-400 hover:bg-opacity-60 cursor-pointer`}
          onClick={() => {
            setInputVisible(true);
            setInputState("");
            ref.current?.focus();
          }}
        >
          {countryNames.includes(inputState) ? (
            <>
              <img
                src={`/flags/${countryCodes[
                  items.indexOf(inputState)
                ]?.toLowerCase()}.svg`}
                alt=""
                className="w-10 h-full"
              />
              <p className="text-ellipsis">{inputState}</p>
            </>
          ) : (
            <p className="text-ellipsis">
              {translate("common:select-a-country")}
            </p>
          )}
        </div>
      )}
      {inputVisible && (
        <div className="z-50 absolute mt-4 overflow-auto max-h-96 bg-gray-700 rounded-xl">
          {items
            .filter((e) => {
              if (e.toLowerCase().includes(inputState.toLowerCase())) return e;
            })
            .map((e, i) => {
              return (
                <div
                  key={i}
                  className="flex items-center gap-4 p-4 hover:bg-gray-400 hover:bg-opacity-60 cursor-pointer"
                  onClick={() => {
                    if (e) {
                      setInputState(e);
                      setInputVisible(false);
                      setIndex(items.indexOf(e));
                    }
                  }}
                >
                  {e}
                </div>
              );
            })}
        </div>
      )}
      {error && <p className="text-crimson">{error}</p>}
    </div>
  );
};

export default ListSearch;
