import React, { useState } from "react";

type AlertType = "success" | "error" | "info";

type Alert = {
  id: number;
  type: AlertType;
  text: string;
};

type AlertCreateFunc = (text: string, timeout?: number) => void;

type AlertContextProps = {
  alerts: Alert[];
  remove: (id: number) => void;
  success: AlertCreateFunc;
  error: AlertCreateFunc;
  info: AlertCreateFunc;
};

const AlertContext = React.createContext<AlertContextProps | null>(null);
AlertContext.displayName = "AlertContext";

type AlertProviderProps = {
  children: React.ReactNode;
};

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const create = (type: AlertType, text: string, timeout: number) => {
    const id = Date.now();

    setAlerts((alerts) => [...alerts, { id: id, type: type, text: text }]);

    setTimeout(() => {
      setAlerts((alerts) => alerts.filter((a) => a.id !== id));
    }, timeout);
  };

  return (
    <AlertContext.Provider
      value={{
        alerts: alerts,
        remove: (id: number) => {
          setAlerts((alerts) => alerts.filter((a) => a.id !== id));
        },
        success: (text: string, timeout: number = 5000) =>
          create("success", text, timeout),
        error: (text: string, timeout: number = 5000) =>
          create("error", text, timeout),
        info: (text: string, timeout: number = 5000) =>
          create("info", text, timeout),
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
