import useUserControlLinks from "../lib/hooks/useUserControlLinks";

import Dropdown from "./Dropdown";

const ProfileDropdown = () => {
  var dropdownItems = useUserControlLinks();
  return <Dropdown buttons={dropdownItems} />;
};

export default ProfileDropdown;
