import { HTTPError } from "ky";
import apiClient from "../api/apiClient";
import IconStar from "../svg/ic_star.svg";
import IconStarEmpty from "../svg/ic_star_empty.svg";
import AlertContext from "../components/context/AlertContext";
import { useContext } from "react";
import { useSelf } from "../api/hooks";
import { useTranslation } from "next-i18next";

type StarRatingProps = {
  userId?: string;
  count: number;
  rating: number;
  showNumber?: boolean;
  large?: boolean;
  onChange?: () => void;
};

const StarRating: React.FC<StarRatingProps> = ({
  userId,
  count,
  rating,
  showNumber,
  large,
  onChange,
}) => {
  const self = useSelf();
  const alert = useContext(AlertContext);
  const { t: translate } = useTranslation("common");
  return (
    <div className="flex items-center">
      <div className="flex">
        {Array.from({ length: 5 }, (_, i) => {
          const Icon = i < Math.round(rating) ? IconStar : IconStarEmpty;

          return (
            <Icon
              key={i}
              onClick={async () => {
                if (!userId) return;
                if (!self.data)
                  return alert?.error(
                    translate("common:you-have-to-be-logged-in" + ".")
                  );

                try {
                  await apiClient.put("ratings", {
                    json: { score: i + 1, userId: userId },
                  });
                  onChange?.();
                } catch (err) {
                  alert?.error(
                    `Failed to update star rating. ${
                      err instanceof HTTPError
                        ? `(${(await err.response.json()).error.message})`
                        : ""
                    }`
                  );
                }
              }}
              className={`${userId && "cursor-pointer"} ${
                large ? "w-8 h-8 mr-2" : "w-5 h-5 mr-1"
              } ${
                i < Math.round(rating)
                  ? `text-primary ${userId && "hover:text-primary-hover"}`
                  : `text-gray-400 ${userId && "hover:text-opacity-50"}`
              }`}
            />
          );
        })}
      </div>
      {showNumber && <p className="ml-4">({Math.round(rating * 10) / 10})</p>}
    </div>
  );
};

export default StarRating;
