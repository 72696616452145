import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {stripePublicKey} from "../../lib/constants";
import CardElementForm from "./CardElementForm";

// @ts-ignore
const CardElementWrapper = ({customerId, resultFunction}) => {
    // console.log('stripePublicKey', stripePublicKey);

    const stripePromise = loadStripe(stripePublicKey);

    return (
        <Elements stripe={stripePromise}>
            <CardElementForm resultFunction={resultFunction} customerId={customerId}/>
        </Elements>
    );
};

export default CardElementWrapper;
