import { useRouter } from "next/router";
import { useSelf } from "../api/hooks";
import isServer from "../lib/isServer";
import useUserStore from "../lib/hooks/useUserStore";
import Dashboard from "../pages/dashboard";
import DashboardEdit from "../pages/dashboard/edit";
import CustomNextPage from "../types/CustomNextPage";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

type PageGuardProps = {
  children: React.ReactNode;
  page: CustomNextPage<unknown>;
};

const PageGuard: React.FC<PageGuardProps> = ({ children, page }) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { cache, accessToken } = useUserStore();
  const isTrainer = cache?.roles.includes("trainer");

  useEffect(() => {
    queryClient.resetQueries();
  }, [accessToken]);

  const self = useSelf();
  if (!page.guard) return <>{children}</>;

  if (!self.isFetched) return null;

  if (!isServer) {
    switch (page.guard) {
      case "anonymous":
        if (cache) {
          router.push(isTrainer ? "/dashboard/videos" : "/home");
          return null;
        }
        break;
      case "logged-in":
        if (!cache) {
          router.push("/");
          return null;
        }
        break;
      case "trainer":
        if (!isTrainer) {
          router.push("/home");
          return null;
        }
        break;
      default:
        break;
    }

    if (cache) {
      if (isTrainer) {
        if (page !== DashboardEdit && !self.data?.trainerProfileFilled) {
          router.push("/dashboard/edit");
          return null;
        }
      } else if (page !== Dashboard && !self.data?.profileFilled) {
        router.push("/dashboard");
        return null;
      }

      if (page === Dashboard && isTrainer) {
        router.replace("/dashboard/edit");
        return null;
      }
    }
  }

  return <>{children}</>;
};
export default PageGuard;
