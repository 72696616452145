import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import IconHamburger from "../../svg/ic_hamburger.svg";
import MQView from "../MQView";
import HamburgerMenuHeader from "./HamburgerMenuHeader";

import HamburgerMenuLink from "./HamburgerMenuLink";
import HamburgerMenuCategoriesPage from "./pages/HamburgerMenuCategoriesPage";
import HamburgerMenuMainPage from "./pages/HamburgerMenuMainPage";
import HamburgerMenuUserControlsPage from "./pages/HamburgerMenuUserControlsPage";

type HamburgerMenuProps = {
  links: HamburgerMenuLink[];
  trainerId?: string;
};

export type HamburgerMenuPage = "main" | "user-controls" | "categories";

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ links, trainerId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState<HamburgerMenuPage>("main");

  const [mainElement, setMainElement] = useState<HTMLElement>();

  useEffect(() => {
    setMainElement(document.getElementsByTagName("main")[0]);
  }, []);

  const menu = (
    <AnimatePresence>
      {isOpen && (
        <MQView query="<lg">
          <motion.div
            initial={{ y: "-100%" }}
            animate={{
              y: "0",
            }}
            exit={{ y: "-100%" }}
            transition={{
              duration: 0.3,
              ease: [0.7, 0, 0.2, 0.9],
            }}
            className="fixed flex flex-col z-900 bg-gray-900 shadow-2xl overflow-hidden"
            style={{ inset: "0 0 0 0", paddingTop: "env(safe-area-inset-top)" }}
          >
            <HamburgerMenuHeader
              page={page}
              setPage={setPage}
              setIsOpen={setIsOpen}
            />
            <AnimatePresence exitBeforeEnter>
              <motion.div
                className="grow min-h-0"
                key={page}
                initial={{ x: page === "main" ? "-100%" : "100%" }}
                animate={{ x: 0 }}
                exit={{ x: page === "main" ? "-100%" : "100%" }}
                transition={{
                  duration: 0.3,
                  ease: [0.7, 0, 0.2, 0.9],
                }}
              >
                {
                  {
                    main: (
                      <HamburgerMenuMainPage
                        trainerId={trainerId}
                        setIsOpen={setIsOpen}
                        setPage={setPage}
                        links={links}
                        key="main"
                      />
                    ),
                    "user-controls": (
                      <HamburgerMenuUserControlsPage
                        setIsOpen={setIsOpen}
                        key="user-controls"
                      />
                    ),
                    categories: (
                      <HamburgerMenuCategoriesPage
                        setIsOpen={setIsOpen}
                        key="categories"
                      />
                    ),
                  }[page]
                }
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </MQView>
      )}
    </AnimatePresence>
  );

  return (
    <>
      <div
        className="w-12 h-12 flex items-center justify-center cursor-pointer"
        onClick={() => {
          setIsOpen(true);
          setPage("main");
        }}
      >
        <IconHamburger />
      </div>
      {mainElement && ReactDOM.createPortal(menu, mainElement)}
    </>
  );
};

export default HamburgerMenu;
