import { Form, Formik } from "formik";
import { HTTPError } from "ky";
import Link from "next/link";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import * as Yup from "yup";
import apiClient from "../../api/apiClient";
import useUserStore from "../../lib/hooks/useUserStore";
import IconArrowLeft from "../../svg/ic_arrow_left.svg";

import ImageEmail from "../../svg/other/img_email.svg";
import Modal from "../../types/Modal";
import Button from "../Button";
import InputField from "../InputField";
import TabList, { Tab } from "../TabList";

import WindowModal, { WindowModalHeader } from "../WindowModal";
import { useRouter } from "next/router";
import Checkbox from "../Checkbox";

type LoginFormModalProps = {
  modal: Modal;
  normalSignup?: boolean;
  trainerId?: string;
  resetPasswordForm?: boolean;
  tab?: number;
  deviceAuth?: boolean;
};

const LoginFormModal: React.FC<LoginFormModalProps> = ({
  modal,
  trainerId,
  normalSignup = false,
  resetPasswordForm = false,
  tab = 0,
  deviceAuth = false,
}) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const { t: translate } = useTranslation();
  const router = useRouter();
  const { setAccessToken } = useUserStore.getState();
  const [resetForm, setResetForm] = useState(resetPasswordForm);
  const [sentEmail, setSentEmail] = useState(false);
  const [loginMessage, setLoginMessage] = useState(
    translate("common:login-form-modal.welcome-back") ?? "Welcome back!"
  );
  const [singUpMessage, setSignUpMessage] = useState(
    translate("common:login-form-modal.join-the-fitter-community") ??
      "Join the Fitter community in 1 minute"
  );
  const [recoveryMessage, setRecoveryMessage] = useState(
    translate(
      "common:login-form-modal.we-will-send-you-an-email-with-recovery-details"
    ) ?? "We'll send you an email with the recovery details."
  );
  const [signUpError, setSignUpError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [recoveryError, setRecoveryError] = useState(false);
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);

  const [tabListBodyContainer, setTabListBodyContainer] =
    useState<HTMLDivElement | null>(null);

  const Scheme = Yup.object().shape({
    email: Yup.string().required(
      translate("common:login-form-modal.an-email-is-required") ??
        "An email is required."
    ),
    password: Yup.string()
      .required(
        translate("common:login-form-modal.a-password-is-required") ??
          "A password is required."
      )
      .min(8),
  });

  return (
    <WindowModal size="full">
      <WindowModalHeader modal={modal} noBorder={!resetForm && !sentEmail}>
        {resetForm ? (
          <>
            {sentVerificationEmail ? (
              <div className="grow" />
            ) : (
              <div
                className="grow cursor-pointer"
                onClick={() => setResetForm(false)}
              >
                <IconArrowLeft />
              </div>
            )}

            {sentEmail ? (
              <div className="grow text-lg">
                {translate("common:login-form-modal.email-sent")}
              </div>
            ) : (
              <div className="grow text-lg">
                {translate("common:login-form-modal.reset-password")}
              </div>
            )}
          </>
        ) : (
          <div className="grow text-center text-lg">
            {normalSignup
              ? translate(
                  "common:login-form-modal.login-or-register-as-a-viewer"
                )
              : translate(
                  "common:login-form-modal.login-or-register-as-a-trainer"
                )}
          </div>
        )}
      </WindowModalHeader>
      <div className="pb-6">
        {resetForm ? (
          sentEmail ? (
            <div className="flex flex-col items-center space-y-10 pt-2 px-6">
              <ImageEmail className="pt-8 w-24 h-auto" />
              <p className="text-center pb-8 px-9">
                {sentVerificationEmail
                  ? translate(
                      "common:login-form-modal.check-your-inbox-activate"
                    )
                  : translate(
                      "common:login-form-modal.check-your-inbox-reset-password"
                    )}
              </p>
            </div>
          ) : (
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ email: "" }}
              onSubmit={async (values) => {
                try {
                  await apiClient.post("reset-password/init", {
                    json: { email: values.email },
                  });
                  setSentEmail(true);
                } catch (err) {
                  setRecoveryMessage(
                    `Failed to send recovery email. ${
                      err instanceof HTTPError
                        ? `(${(await err.response.json()).error.message})`
                        : ""
                    }`
                  );
                  setRecoveryError(true);
                  const timeout = setTimeout(() => {
                    setRecoveryMessage(
                      translate(
                        "common:login-form-modal.join-the-fitter-community"
                      ) ?? "Join the Fitter community in 1 minute"
                    );
                    setRecoveryError(false);
                    clearTimeout(timeout);
                  });
                }
              }}
            >
              {({ values, isSubmitting }) => (
                <Form className="space-y-10 pt-2 px-6">
                  <p
                    className={`text-center ${recoveryError && "text-crimson"}`}
                  >
                    {recoveryMessage}
                  </p>
                  <InputField
                    name="email"
                    type="email"
                    label={
                      translate("common:login-form-modal.email-address") ??
                      "Email address"
                    }
                    placeholder="johndoe@email.com"
                  />
                  <Button type="submit" size="full" loading={isSubmitting}>
                    {translate("common:login-form-modal.send-recovery-email")}
                  </Button>
                </Form>
              )}
            </Formik>
          )
        ) : (
          <>
            {tabListBodyContainer && (
              <TabList
                className="justify-center mx-20"
                tabIndex={tab}
                targetElement={tabListBodyContainer}
              >
                <Tab title={translate("common:sign-up")}>
                  <Formik
                    validationSchema={Scheme}
                    initialValues={{ email: "", password: "" }}
                    onSubmit={async (values) => {
                      await apiClient
                        .post(
                          `${normalSignup ? "signup" : "trainers/signup"}`,
                          {
                            json: {
                              email: values.email,
                              password: values.password,
                              trainerIdRegisteredFrom: trainerId,
                              // registered from is provided by the url query parameter
                              urlRegisteredFrom: router.query.registeredFrom,
                            },
                          }
                        )
                        .then(() => {
                          setSentVerificationEmail(true);
                          setResetForm(true);
                          setSentEmail(true);
                        })
                        .catch(async (err: HTTPError) => {
                          const response = JSON.parse(
                            await err.response.text()
                          );
                          setSignUpMessage(
                            `Failed to sign up in. ${
                              response ? `(${response.error.message})` : ""
                            }`
                          );
                          setSignUpError(true);
                          const timeout = setTimeout(() => {
                            setSignUpMessage(
                              translate(
                                "common:login-form-modal.join-the-fitter-community"
                              ) ?? "Join the Fitter community in 1 minute"
                            );
                            setSignUpError(false);
                            clearTimeout(timeout);
                          }, 8000);
                        });
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form className="space-y-6 pt-2 px-6" tabIndex={tab}>
                        <p
                          className={`text-center ${
                            signUpError && "text-crimson"
                          }`}
                        >
                          {singUpMessage}
                        </p>
                        <InputField
                          name="email"
                          type="email"
                          label={
                            translate(
                              "common:login-form-modal.email-address"
                            ) ?? "Email address"
                          }
                          placeholder="johndoe@email.com"
                        />
                        <InputField
                          name="password"
                          type="password"
                          label={
                            translate("common:login-form-modal.password") ??
                            "Password"
                          }
                          placeholder={
                            translate(
                              "common:login-form-modal.should-have-at-least-eight-characters"
                            ) ?? "Should have at least 8 characters"
                          }
                        />
                        <Checkbox
                          checked={termsChecked}
                          setChecked={setTermsChecked}
                        >
                          <p className="text-center text-gray-400">
                            <span>
                              {translate(
                                "common:login-form-modal.by-signing-up-you-accept-our"
                              )}{" "}
                            </span>
                            <Link href="/terms" locale={false}>
                              <a className="text-primary">
                                {translate("common:terms-of-service")}
                              </a>
                            </Link>
                            <span>
                              {" "}
                              {translate("common:login-form-modal.and")}{" "}
                            </span>
                            <Link href="/privacy" locale={false}>
                              <a className="text-primary">
                                {translate("common:privacy-policy")}.
                              </a>
                            </Link>
                          </p>
                        </Checkbox>
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          size="full"
                          disabled={!termsChecked}
                        >
                          {translate("common:sign-up")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Tab>
                <Tab title={translate("common:log-in")}>
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={Scheme}
                    initialValues={{ email: "", password: "" }}
                    onSubmit={async (values) => {
                      try {
                        const res: any = await apiClient
                          .post("login", { json: values })
                          .json();
                        if (res.token) {
                          setAccessToken(res.token);
                          modal.hide();
                        }
                        if (!deviceAuth && res.redirectTo) {
                          await router.push(res.redirectTo);
                        }
                      } catch (err) {
                        if (err instanceof HTTPError) {
                          const response = JSON.parse(
                            await err.response.text()
                          );
                          setLoginMessage(
                            `Failed to sign in. ${
                              response ? `(${response.error.message})` : ""
                            }`
                          );
                          setLoginError(true);
                          const timeout = setTimeout(() => {
                            setLoginMessage(
                              translate(
                                "common:login-form-modal.welcome-back"
                              ) ?? "Welcome back!"
                            );
                            setLoginError(false);
                            clearTimeout(timeout);
                          }, 8000);
                        }
                      }
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form className="space-y-6 pt-2 px-6">
                        <p
                          className={`text-center ${
                            loginError && "text-crimson"
                          }`}
                        >
                          {loginMessage}
                        </p>
                        <InputField
                          name="email"
                          type="email"
                          label={
                            translate(
                              "common:login-form-modal.email-address"
                            ) ?? "Email address"
                          }
                          placeholder="johndoe@email.com"
                        />
                        <InputField
                          name="password"
                          type="password"
                          label={
                            translate("common:login-form-modal.password") ??
                            "Password"
                          }
                          placeholder={
                            translate(
                              "common:login-form-modal.your-secret-password"
                            ) ?? "Your secret password"
                          }
                        />
                        <Button
                          variant="text"
                          className="ml-auto"
                          size="min"
                          type="button"
                          onClick={() => setResetForm(true)}
                        >
                          {translate("common:login-form-modal.forgot-password")}
                        </Button>
                        <Button
                          size="full"
                          type="submit"
                          loading={isSubmitting}
                        >
                          {translate("common:log-in")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Tab>
              </TabList>
            )}
            <div
              className="border-t border-gray-700"
              ref={(el) => setTabListBodyContainer(el)}
            />
          </>
        )}
      </div>
    </WindowModal>
  );
};

export default LoginFormModal;
