import { useRef } from "react";

type FilePickerProps = {
  onFiles: (files: FileList) => void;
  disabled?: boolean;
  children: React.ReactNode;
  inline?: boolean;
};

const FilePicker: React.FC<FilePickerProps> = ({
  onFiles,
  disabled = false,
  children,
  inline = false,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div
      onClick={() => {
        if (ref.current && !disabled) {
          ref.current.value = "";
          ref.current.click();
        }
      }}

      style={inline ? {display: "inline-block"} : {}}
    >
      {children}
      <input
        className="hidden"
        type="file"
        name="file"
        ref={ref}
        onChange={(e) => {
          const files = e.currentTarget.files;
          if (files && files.length > 0) onFiles(files);
        }}
      />
    </div>
  );
};

export default FilePicker;
