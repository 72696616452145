import { AxiosError } from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { useTranslation } from "next-i18next";
import { useMutation } from "react-query";

import { axiosClient } from "../api/apiClient";
import { formatTime } from "../lib/timeFormatting";

import IconCheckmark from "../svg/ic_checkmark.svg";

import AlertContext from "./context/AlertContext";
import FilePicker from "./FilePicker";
import LoadingSpinner from "./LoadingSpinner";

export type VideoProps = {
  vodId: string;
  filePath: string;
  length: number;
  downloadFileKey: string;
};

export type VideoUploaderProps = {
  postUrl: string;
  onStatusChange: (
    status: "uploading" | "finished",
    response: VideoProps | null
  ) => void;
  children?: React.ReactNode;
  full?: boolean;
  className?: string;
};

const VideoUploader: React.FC<VideoUploaderProps> = ({
  postUrl,
  onStatusChange,
  children,
  full,
  className = "",
}) => {
  const { t: translate } = useTranslation("common");
  const alert = useContext(AlertContext);
  const [progress, setProgress] = useState<ProgressEvent[]>([]);

  const { isLoading, isSuccess, mutateAsync } = useMutation<
    VideoProps,
    AxiosError,
    FormData
  >(
    async (formData) =>
      (
        await axiosClient.post(postUrl, formData, {
          timeout: 0,
          onUploadProgress: (e: ProgressEvent) => {
            setProgress((arr) => [e, ...arr].slice(0, 5));
          },
        })
      ).data
  );

  const calculatePercentage = (e: ProgressEvent): number => e.loaded / e.total;

  const calculateTimeRemaining = (events: ProgressEvent[]): number => {
    let totalUploaded = 0;
    for (let i = 0; i < events.length - 1; i++) {
      const diff = events[i].loaded - events[i + 1].loaded;
      totalUploaded += diff;
    }

    const averageUploadPerEvent = totalUploaded / (events.length - 1);
    const averageTimePerEvent =
      (events[0].timeStamp - events[events.length - 1].timeStamp) /
      (events.length - 1);

    const uploadSpeed = averageUploadPerEvent / (averageTimePerEvent / 1000);

    return (events[0].total - events[events.length - 1].loaded) / uploadSpeed;
  };

  const percentage =
    progress.length > 0 ? calculatePercentage(progress[0]) : -1;
  const timeRemaining =
    progress.length > 0 ? calculateTimeRemaining(progress) : -1;

  const upload = async (files: FileList) => {
    setProgress([]);
    onStatusChange("uploading", null);

    const formData = new FormData();
    formData.append("file", files[0]);

    try {
      onStatusChange("finished", await mutateAsync(formData));
    } catch (error: any) {
      if (error.response)
        alert?.error(
          translate("common:network-error-occurred-while-uploading-file")
        );
    }
  };

  return (
    <FilePicker disabled={isLoading} onFiles={upload}>
      <div
        className={`${className} relative ${
          full ? "w-full" : "w-96 h-52"
        } p-8 overflow-hidden rounded-2xl bg-gray-700 flex flex-col items-center justify-center ${
          isLoading ? "" : "cursor-pointer"
        } hover:bg-opacity-60 transition-colors`}
      >
        <AnimatePresence>
          {isLoading ? (
            <motion.div
              initial={{ height: 0, y: 15, opacity: 0 }}
              animate={{ height: "auto", y: 0, opacity: 1 }}
              exit={{ height: 0, y: 0, opacity: 0 }}
              className="flex flex-col gap-1 text-center w-full"
            >
              <p>{translate("common:uploading-video")}...</p>
              {percentage >= 0 && percentage < 1 && (
                <>
                  <div className="h-2 bg-gray-900 rounded-full w-full overflow-hidden">
                    <div
                      className="bg-primary h-full transition-all duration-75"
                      style={{
                        width: `${percentage * 100}%`,
                      }}
                    />
                  </div>
                  <p
                    className={`${
                      timeRemaining > 0 ? "opacity-100" : "opacity-0"
                    } transition`}
                  >{`${formatTime(timeRemaining)} left`}</p>
                </>
              )}
              {percentage === 1 && (
                <div className="flex gap-4 items-center justify-center">
                  <div>
                    <LoadingSpinner />
                  </div>
                  <p>{translate("common:finishing-up")}</p>
                </div>
              )}
            </motion.div>
          ) : (
            children
          )}
        </AnimatePresence>
        {isSuccess && (
          <motion.div
            animate={{
              opacity: [0, 1, 1, 0],
              transitionEnd: {
                display: "none",
              },
            }}
            transition={{
              ease: "easeOut",
              duration: 3,
              times: [0, 0.15, 0.85, 1],
            }}
            className="absolute inset-0 bg-black bg-opacity-70"
          >
            <motion.p
              initial={{ scale: 1.15 }}
              animate={{ scale: 0.95 }}
              transition={{ ease: "circOut", duration: 3 }}
              className="w-full h-full flex items-center justify-center gap-2"
            >
              <IconCheckmark />
              {translate("common:upload-completed")}!
            </motion.p>
          </motion.div>
        )}
      </div>
    </FilePicker>
  );
};

export default VideoUploader;
