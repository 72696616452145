import React from "react";

type FixedRatioProps = React.ComponentPropsWithoutRef<"div"> & {
  ratio: number;
  className?: string;
};

const FixedRatio = React.forwardRef<HTMLDivElement, FixedRatioProps>(
  function FixedRatio({ ratio, className = "", children, ...props }, ref) {
    return (
      <div className={`ratio-container relative ${className}`} {...props}>
        <div
          ref={ref}
          className="absolute inset-0 flex justify-center bg-transparent"
        >
          {children}
        </div>
        <style jsx>{`
          .ratio-container::before {
            display: block;
            content: "";
            padding-top: ${(1 / ratio) * 100}%;
          }
        `}</style>
      </div>
    );
  }
);

export default FixedRatio;
