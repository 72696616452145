import { motion } from "framer-motion";
import React from "react";

import IconClose from "../svg/ic_close.svg";
import Modal from "../types/Modal";

type WindowModalHeaderProps = {
  noBorder?: boolean;
  modal: Modal;
  children: React.ReactNode;
};

export const WindowModalHeader: React.FC<WindowModalHeaderProps> = ({
  children,
  noBorder,
  modal,
}) => {
  return (
    <div
      className={`flex items-center p-6 py-8 ${
        noBorder ? "" : "border-b border-gray-700"
      }`}
    >
      {children}
      <p className="cursor-pointer" onClick={() => modal.hide()}>
        <IconClose />
      </p>
    </div>
  );
};

type WindowModalProps = {
  size?: "full" | "min" | "narrow";
  children: React.ReactNode;
};

const WindowModal: React.FC<WindowModalProps> = ({
  size = "min",
  children,
}) => {
  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{
        scale: 1,
        transition: {
          type: "spring",
          bounce: 0.5,
          duration: 0.3,
        },
      }}
      exit={{ scale: 0.9, transition: { duration: 0.2 } }}
      className={`bg-gray-900 rounded-xl  ${
        size === "min" ? "w-full max-w-lg" : ""
      } md:max-w-xl max-w-lg w-full mx-auto ${
        size === "narrow" ? "w-4/6" : ""
      }`}
    >
      {children}
    </motion.div>
  );
};

export default WindowModal;
