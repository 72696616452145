import ImageSourceSet from "../types/ImageSourceSet";

// removes 'p' suffix and sorts based on quality ascending
export const convertSourceSet = (srcSet: ImageSourceSet): ImageSourceSet =>
  Object.keys(srcSet)
    .sort((a, b) =>
      parseInt(a.slice(0, -1)) > parseInt(b.slice(0, -1)) ? 1 : -1
    )
    .reduce((obj: ImageSourceSet, key) => {
      obj[parseInt(key.slice(0, -1))] = srcSet[key];
      return obj;
    }, {});

// Returns the quality closest to the provided target
// If an exact match is not found, prefer larger values
export const getClosestQualityUrl = (
  target: number,
  srcSet: ImageSourceSet
): string => {
  const keys = Object.keys(srcSet).reverse();

  let prev = keys[0];
  for (let i = 1; i < keys.length; i++) {
    if (parseInt(keys[i]) < target) break;
    prev = keys[i];
  }
  
  return srcSet[prev];
};
