import React from "react";
import { useField } from "formik";

import ListBox, { ListBoxProps } from "./ListBox";

const ListBoxField: React.FC<
  Omit<
    ListBoxProps & {
      name: string;
    },
    "index" | "setIndex"
  >
> = (props) => {
  const {onChange, ...props_} = props;
  const [field, meta, helpers] = useField(props_);

  return (
    <ListBox
      error={meta.error}
      index={meta.value}
      setIndex={(index: number) => helpers.setValue(index)}
      {...field}
      {...props}
    />
  );
};

export default ListBoxField;
