import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Stream } from "../../api/entities";
import { getStreamByID, getTrainerByID } from "../../api/wrapper";
import { backendUrl } from "../../lib/constants";

export type StreamWithFollowCount = Stream & { trainerFollowCount: number };

type WebSocketContextProps = {
  runningStreams: Map<string, StreamWithFollowCount>;
};

const WebSocketContext = React.createContext<WebSocketContextProps>({
  runningStreams: new Map(),
});

type StreamStatusUpdateMessage = {
  streamId: string;
};

type WebSocketProviderProps = {
  shouldConnect: boolean;
  children: React.ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  shouldConnect,
  children,
}) => {
  const socketExistsRef = useRef(false);

  const [runningStreams, setRunningStreams] = useState<
    Map<string, StreamWithFollowCount>
  >(new Map());

  useEffect(() => {
    if (socketExistsRef.current || !shouldConnect) return;
    socketExistsRef.current = true;

    const socket = io(backendUrl, {
      transports: ["websocket"],
    });

    socket.on("streamStarted", async (data: StreamStatusUpdateMessage) => {
      try {
        const stream = await getStreamByID(data.streamId);
        const trainer = await getTrainerByID(stream.userId);
        setRunningStreams((prev) => {
          const newMap = new Map(prev);
          newMap.set(stream.userId, {
            ...stream,
            trainerFollowCount: trainer.followersCount,
          });
          return newMap;
        });
      } catch (e) {
        console.error(
          "Failed to get stream by ID returned from WS streamStarted event",
          e
        );
      }
    });

    socket.on("streamStopped", async (data: StreamStatusUpdateMessage) => {
      setRunningStreams((prev) => {
        const newMap = new Map(prev);
        for (const key of Array.from(newMap.keys())) {
          if (newMap.get(key)!!.id === data.streamId) {
            newMap.delete(key);
            break;
          }
        }
        return newMap;
      });
    });

    return () => {
      socket.close();
      socketExistsRef.current = false;
    };
  }, [shouldConnect]);

  return (
    <WebSocketContext.Provider value={{ runningStreams: runningStreams }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
