import { useQuery } from "react-query";
import useUserStore from "../lib/hooks/useUserStore";
import {
  getCategories,
  getCourseByID,
  getSelf,
  getStreamByID,
  getTrainerByID,
  getVideoByID,
  getVideoPaymentIntentByID,
} from "./wrapper";

export const useSelf = () => useQuery("self", getSelf, { retry: false });
export const useCategories = () => useQuery("categories", getCategories);
export const useOwnTrainerProfile = () => {
  const { cache } = useUserStore();

  return useQuery("ownTrainerProfile", () => {
    if (!cache) throw new Error("not logged in");

    return getTrainerByID(cache.id!);
  });
};
export const useVideoByID = (id: string) =>
  useQuery(["videos", id], () => getVideoByID(id), { enabled: !!id });
export const useCourseByID = (id: string) =>
  useQuery(["courses", id], () => getCourseByID(id), { enabled: !!id });
export const useStreamByID = (id: string) =>
  useQuery(["streams", id], () => getStreamByID(id), { enabled: !!id });
