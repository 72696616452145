import { useEffect, useState } from "react";
import debounce from "../debounce";
import isServer from "../isServer";

// A hook for getting the viewport size with debouncing,
// so it doesn't lag the browser
const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState(getViewportSize());

  useEffect(() => {
    const resizeHandler = () => {
      setViewportSize(getViewportSize());
    };

    const debouncedResizeHandler = debounce(resizeHandler, 1000);

    if (window.visualViewport)
      window.visualViewport.addEventListener("resize", debouncedResizeHandler);
    else window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      debouncedResizeHandler.cancel();

      if (window.visualViewport)
        window.visualViewport.removeEventListener(
          "resize",
          debouncedResizeHandler
        );
      else window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, []);

  return viewportSize;
};

const getViewportSize = () => {
  // VisualViewport is a new API, fallback if it doesn't exist
  if (isServer)
    return {
      width: 0,
      height: 0,
    };

  return {
    width: window.visualViewport?.width ?? window.innerWidth ?? 0,
    height: window.visualViewport?.height ?? window.innerHeight ?? 0,
  };
};

export default useViewportSize;
