import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import Modal from "../../types/Modal";
import WindowModal, { WindowModalHeader } from "../WindowModal";
import StripePaymentForm from "../StripePaymentForm";
import React, { useState, useEffect, useRef, useContext } from "react";
import apiClient, { axiosClient } from "../../api/apiClient";
import { PaymentIntent } from "../../api/entities";
import LoadingSpinner from "../LoadingSpinner";
import { useSelf } from "../../api/hooks";
import { Form, Formik, FormikProps } from "formik";
import RadioButtonField from "../RadioButtonField";
import InputField from "../InputField";
import ListSearchField from "../ListSearchField";
import countries from "countries-list";
import * as Yup from "yup";
import Button from "../Button";
import { stripePublicKey } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import AlertContext from "../context/AlertContext";
import { HTTPError } from "ky";

type PaymentModalProps = {
  modal: Modal;
  type: string;
  itemId?: string;
  stripeAccount: string;
};

const PaymentModal: React.FC<PaymentModalProps> = ({
  modal,
  type,
  itemId,
  stripeAccount,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
  const { t: translate } = useTranslation("common");
  const invoiceRef = useRef<FormikProps<any> | null>(null);
  const taxSubjects = ["person", "company"];
  const countryCodes = Object.keys(countries.countries);
  const countryNames = Object.values(countries.countries).map(
    (item) => item.name
  );
  const Schema4 = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    city: Yup.string().required("A city is required."),
    country: Yup.number().min(0, "A country is required."),
    zip: Yup.string().required("A zip code is required."),
    address: Yup.string().required("An address is required."),
    taxNumber: Yup.string().when("taxSubject", {
      is: 1,
      then: (schema) => schema.required("A tax number is required."),
    }),
    companyName: Yup.string().when("taxSubject", {
      is: 1,
      then: (schema) => schema.required("A company name is required."),
    }),
    state: Yup.string().required("A state is required.").nullable(),
    taxSubject: Yup.number(),
  });
  const alert = useContext(AlertContext);
  const self = useSelf();
  const addressSet =
    !!self?.data?.address &&
    !!self?.data?.city &&
    !!self?.data?.country &&
    !!self?.data?.taxSubject &&
    !!self?.data?.name;

  useEffect(() => {
    if (!addressSet) {
      setLoading(false);
      setPaymentIntent(undefined);
      return;
    }

    let url;

    if (type === "video") {
      url = `purchases/payment-intent/video/${itemId}`;
    }
    if (type === "course") {
      url = `purchases/payment-intent/course/${itemId}`;
    }
    if (type === "stream") {
      url = `purchases/payment-intent/stream/${itemId}`;
    }
    if (type === "monthlySubscription") {
      url = `purchases/payment-intent/subscription/monthly/${itemId}`;
    }
    if (type === "yearlySubscription") {
      url = `purchases/payment-intent/subscription/yearly/${itemId}`;
    }

    if (type === "eventTicketPurchase") {
      url = `purchases/payment-intent/event-ticket/${itemId}`;
    }
    if (url) {
      axiosClient.get(url).then((response) => {
        setPaymentIntent(response.data);
        setLoading(false);
      });
    }
  }, [addressSet]);

  if (!self.data) return null;

  if (!addressSet) {
    return (
      <WindowModal size="min">
        <WindowModalHeader modal={modal}>
          <span></span>
        </WindowModalHeader>
        <Formik
          innerRef={invoiceRef}
          initialValues={{
            country: countryCodes.indexOf("HU"),
            city: self.data.city ?? "",
            zip: self.data.zip ?? "",
            address: self.data.address ?? "",
            taxNumber: self.data.taxNumber ?? "",
            taxSubject: self.data.taxSubject === "company" ? 1 : 0,
            companyName: self.data.companyName ?? "",
            state: self.data.state,
          }}
          validateOnChange={false}
          onSubmit={async (values) => {
            let reqBody: any = {
              country: countryCodes[values.country],
              name: values.name,
              city: values.city,
              zip: values.zip,
              address: values.address,
              taxSubject: taxSubjects[values.taxSubject],
              state: values.state,
            };
            if (values.taxSubject === 1)
              reqBody = {
                ...reqBody,
                taxNumber: values.taxNumber,
                companyName: values.companyName,
              };

            apiClient
              .patch("users/update-profile", {
                json: reqBody,
              })
              .then(async () => {
                await apiClient
                  .put("users/set-profile-filled", { json: {} })
                  .catch((err) => {
                    // error usually occurs when the user profile is already set
                    alert?.error(
                      "An error occurred while updating your profile."
                    );
                  });
                self.refetch();
              });
          }}
          validateOnBlur={false}
          validationSchema={Schema4}
        >
          {({ values }) => (
            <div
              className="flex gap-16 justify-center w-full"
              style={{ padding: "30px" }}
            >
              <div className="flex flex-col space-y-8 w-full max-w-3xl">
                <Form>
                  <div className="space-y-4">
                    <h2>
                      {translate("common:paymodal.invoice-data-for-payment")}
                    </h2>
                    <InputField
                      className="flex-1"
                      name="name"
                      label={translate("common:paymodal.name") ?? ""}
                    />
                    <div className="flex gap-5 items-center">
                      <RadioButtonField
                        name="taxSubject"
                        buttons={[
                          translate("common:paymodal.person"),
                          translate("common:paymodal.company"),
                        ]}
                      />
                      {!!values.taxSubject && (
                        <InputField
                          placeholder={
                            translate("common:paymodal.add-company-name") ?? ""
                          }
                          className="flex-1"
                          name="companyName"
                          label={
                            translate("common:paymodal.company-name") ?? ""
                          }
                        />
                      )}
                    </div>

                    <div className="flex gap-x-10">
                      <ListSearchField
                        items={countryNames}
                        name="country"
                        label={translate("common:paymodal.country") ?? ""}
                      />
                      <InputField
                        placeholder={
                          translate("common:paymodal.add-city") ?? ""
                        }
                        className="flex-1"
                        name="city"
                        label={translate("common:paymodal.city") ?? ""}
                      />
                    </div>
                    <InputField
                      placeholder={
                        translate(
                          "common:paymodal.street-level-apartment-number"
                        ) ?? ""
                      }
                      name="address"
                      label={translate("common:paymodal.address") ?? ""}
                    />
                    <InputField
                      placeholder={
                        translate("common:paymodal.add-state-or-district") ?? ""
                      }
                      className="flex-1"
                      name="state"
                      label={
                        translate("common:paymodal.state-or-district") ?? ""
                      }
                    />
                    <div className="flex gap-x-5">
                      <InputField
                        placeholder={translate("common:zip-code") ?? "Zip Code"}
                        className="flex-1"
                        name="zip"
                        label={translate("common:paymodal.zip-code") ?? ""}
                      />
                      {values.taxSubject ? (
                        <InputField
                          placeholder={
                            translate("common:paymodal.add-vat-number") ?? ""
                          }
                          className="flex-1"
                          name="taxNumber"
                          label={translate("common:paymodal.vat") ?? "" + "*"}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {!!values.taxSubject && (
                      <p className="text-sm  text-gray-400 ml-auto w-52">
                        *
                        {translate(
                          "common:paymodal.please-use-eu-vat-if-your-company-is-based-in-the-eu"
                        )}
                      </p>
                    )}
                    <div
                      style={{
                        margin: "20px 0",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button type={"submit"}>Next</Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </WindowModal>
    );
  }

  if (isLoading || !paymentIntent) {
    return <LoadingSpinner size="large" />;
  }

  const clientSecret = paymentIntent.clientSecret;

  const stripePromise = loadStripe(stripePublicKey, {
    stripeAccount: stripeAccount,
  });

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    loader: "always",
    appearance: {
      theme: "night",
      labels: "above",
    },
  };

  return (
    <WindowModal size="min">
      <WindowModalHeader modal={modal}>
        <span></span>
      </WindowModalHeader>
      <div className="grow text-lg text-center" style={{ padding: "30px" }}>
        <Elements stripe={stripePromise} options={options}>
          <StripePaymentForm></StripePaymentForm>
        </Elements>
      </div>
    </WindowModal>
  );
};

export default PaymentModal;
