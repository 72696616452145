import axios, { AxiosRequestTransformer } from "axios";
import ky from "ky";
import { apiPrefixUrl } from "../lib/constants";
import useUserStore from "../lib/hooks/useUserStore";

const defaultTransformers = (): AxiosRequestTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) {
    return [];
  } else if (transformRequest instanceof Array) {
    return transformRequest;
  } else {
    return [transformRequest];
  }
};

export const axiosClient = axios.create({
  baseURL: apiPrefixUrl,
  headers: { "Content-Type": "application/json" },

  transformRequest: [
    ...defaultTransformers(),
    (data, headers) => {
      const { accessToken } = useUserStore.getState();

      if (accessToken)
        (headers ?? {})["Authorization"] = `Bearer ${accessToken}`;

      return data;
    },
  ],
});

const apiClient = ky.extend({
  prefixUrl: apiPrefixUrl,
  timeout: 60 * 1000,
  hooks: {
    beforeRequest: [
      (req) => {
        const { accessToken } = useUserStore.getState();

        if (accessToken)
          req.headers.set("Authorization", `Bearer ${accessToken}`);
      },
    ],
  },
});

export default apiClient;
