import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import apiClient from "../../api/apiClient";
import { useCategories, useSelf } from "../../api/hooks";
import {
  getTrainerByID,
  getTrainerByName,
  getVideoByID,
} from "../../api/wrapper";

import useUserStore from "../../lib/hooks/useUserStore";
import IconArrowLeft from "../../svg/ic_arrow_left.svg";
import IconArrowRight from "../../svg/ic_arrow_right.svg";
import IconBackToHome from "../../svg/ic_logout.svg";

import IconChevronDown from "../../svg/ic_chevron_down.svg";
import IconChevronUp from "../../svg/ic_chevron_up.svg";
import IconFavorite from "../../svg/ic_favorite.svg";
import IconFavoriteFilled from "../../svg/ic_favorite_filled.svg";

import LongLogo from "../../svg/other/long_logo.svg";
import LayoutProps from "../../types/LayoutProps";

import Button from "../Button";

import { useTranslation } from "next-i18next";
import preval from "preval.macro";
import { cn } from "../../lib/cn";
import useMobileAppStore from "../../lib/hooks/useMobileAppStore";
import useViewportSize from "../../lib/hooks/useViewportSize";
import BuyEventButton from "../BuyEventButton";
import CategoryCard from "../CategoryCard";
import LanguagePicker from "../LanguagePicker";
import LazyLoadedImg from "../LazyLoadedImg";
import MQView from "../MQView";
import ProfileDropdown from "../ProfileDropdown";
import Search from "../Search";
import StarRating from "../StarRating";
import SubscribeButton from "../SubscribeButton";
import Tags from "../Tags";
import ModalContext from "../context/ModalContext";
import HamburgerMenu from "../hamburger-menu/HamburgerMenu";
import LoginFormModal from "../modal/LoginFormModal";
const screens = preval`
  module.exports = require("tailwindcss/resolveConfig")(require("../../../tailwind.config.js")).theme.screens;
`;

const MainLayout: React.FC<LayoutProps> = ({ children, layoutProps }) => {
  const router = useRouter();

  const { width, height } = useViewportSize();
  const { setIsMobileAppView, isMobileAppView } = useMobileAppStore();

  let isLandscape = width > height;
  if (typeof window !== "undefined") {
    if (!isMobileAppView && localStorage.getItem("app") === "true")
      setIsMobileAppView(true);
  }
  const mobileView =
    width < parseInt(screens["md"].slice(0, -2)) || isMobileAppView;
  const modalContext = useContext(ModalContext);
  const categories = useCategories();
  const trainerUsername = layoutProps?.trainerUsername;

  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [active, setActive] = useState(0);

  const [following, setFollowing] = useState(false);
  const { t: translate, ready } = useTranslation("common");
  const { data: videoDataForFooter } = useQuery("videoDataForFooter", () =>
    getVideoByID(router.query.id as string)
  );
  useEffect(() => {
    setCategoriesOpen(false);
  }, [router.asPath]);

  // const trainerQuery = useQuery(
  //   ["trainer", trainerUsername],
  //   () => getTrainerByName(trainerUsername!),
  //   { enabled: !!trainerUsername }
  // );
  let trainerId: string | undefined = undefined;
  if (router.pathname.includes("profiles/[id]")) {
    trainerId = router.query.id as string;
  }
  if (router.pathname.endsWith("videos/[id]")) {
    trainerId = videoDataForFooter?.userId;
  }

  const trainerQuery = useQuery("trainerDataForFooter", () => {
    if (router.pathname.endsWith("videos/[id]"))
      return getTrainerByID(trainerId as string);
    return getTrainerByName(trainerId as string);
  });
  useEffect(() => {
    if (trainerId !== trainerQuery.data?.slug && !trainerQuery.isLoading) {
      if (trainerId) trainerQuery.refetch();
    }
    if (data && trainerQuery.data?.followers) {
      if (
        trainerQuery.data.followers.indexOf(data.id) >= 0 &&
        trainerQuery.data.followers.length != 0
      )
        setFollowing(true);
      else setFollowing(false);
    } else {
      setFollowing(false);
    }
  }, [trainerQuery.data, trainerId, trainerQuery.isLoading, trainerQuery]);

  const userStore = useUserStore();
  // undefined = unknown, localStorage is not available on server
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  useEffect(() => {
    setIsLoggedIn(!!userStore.cache);
  }, [userStore.cache]);

  const { isLoading, error, data } = useSelf();
  const navLinksNormal = [
    // {
    //   label: translate("m-layout.trending-link"),
    //   href: `/trending`,
    // },
    { label: translate("common:m-layout.coming-up-link"), href: `/comingup` },
    {
      label: translate("common:m-layout.training-courses-link"),
      href: `/courses`,
    },
  ];

  const navLinksTrainer = [
    {
      label: translate("common:m-layout.home-link"),
      href: `/profiles/${trainerUsername}`,
    },
    {
      label: translate("common:m-layout.schedule-link"),
      href: `/profiles/${trainerUsername}/schedule`,
    },
    {
      label: translate("common:m-layout.training-courses-link"),
      href: `/profiles/${trainerUsername}/courses`,
    },
    {
      label: translate("common:m-layout.videos-link"),
      href: `/profiles/${trainerUsername}/videos`,
    },
    {
      label: translate("common:m-layout.bio-link"),
      href: `/profiles/${trainerUsername}/bio`,
    },
  ];

  useEffect(() => {
    setActive(
      navLinksNormal.findIndex(
        (x) =>
          x.href ===
          "/" +
            router.pathname.split("/")[router.pathname.split("/").length - 1]
      )
    );
  }, [router.asPath]);

  useEffect(() => {
    if (router.asPath === `/profiles/${trainerUsername}`) setActive(0);
    else
      setActive(
        navLinksTrainer.findIndex(
          (x) =>
            x.href.split("/")[x.href.split("/").length - 1] ===
            router.pathname.split("/")[router.pathname.split("/").length - 1]
        )
      );
  }, [trainerUsername, router.isReady]);

  const [navLinks, setNavLinks] = useState(navLinksNormal);

  useEffect(() => {
    if (router.pathname.includes("profiles")) {
      setNavLinks(navLinksTrainer);
    } else {
      setNavLinks(navLinksNormal);
    }
  }, [trainerUsername]);

  const profileTags = useMemo(
    () =>
      categories.data
        ? categories.data
            .filter((cat) => trainerQuery.data?.categories.includes(cat.id))
            .map((cat) => cat.name)
        : [],
    [categories.data, trainerQuery.data]
  );
  const [stickyDisabled, setStickyDisabled] = useState(false);
  const [previousScroll, setPreviousScroll] = useState(0);

  useEffect(() => {
    const mainElement = document.querySelector("body");
    const handleScroll = () => {
      let scrolledEnough =
        mainElement?.scrollTop! >= 200 || window.scrollY >= 200;
      if (mobileView || isMobileAppView) {
        scrolledEnough = mainElement?.scrollTop! >= 20 || window.scrollY >= 20;
      }
      if (scrolledEnough) {
        setStickyDisabled(true);
      } else {
        setStickyDisabled(false);
      }

      // if (mainElement?.scrollTop! < previousScroll && stickyDisabled) {
      //   setStickyDisabled(false);
      // }
      // setPreviousScroll(window.scrollY);
    };
    if (!isMobileAppView) {
      window?.addEventListener("scroll", handleScroll);
    } else {
      mainElement?.addEventListener("scroll", handleScroll);
    }

    // Cleanup
    return () => {
      if (!isMobileAppView) {
        mainElement?.removeEventListener("scroll", handleScroll);
      } else {
        mainElement?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isMobileAppView, previousScroll, stickyDisabled, mobileView]);
  return (
    <div className="flex flex-col h-full">
      {!router.query.hideAllMenu &&
        (isMobileAppView && router.pathname.includes("profiles/") ? (
          <div
            style={{ marginTop: "env(safe-area-inset-top)" }}
            className={`z-900 flex flex-row items-center justify-between pl-[env(safe-area-inset-left)] pr-[env(safe-area-inset-right)] w-full transition-all`}
          >
            <IconArrowLeft
              onClick={() => router.back()}
              className="w-8 ml-8 cursor-pointer"
            />
            <div className="text-lg">{trainerQuery.data?.name}</div>
            <div className="lg:hidden mr-8">
              <HamburgerMenu
                trainerId={trainerQuery.data?.id}
                links={navLinks}
              />
            </div>
          </div>
        ) : (
          <div
            style={{ marginTop: "env(safe-area-inset-top)" }}
            className={cn(
              `z-900 flex pt-1 flex-col pl-[env(safe-area-inset-left)] pr-[env(safe-area-inset-right)] w-full transition-all bg-opacity-0 fixed`
            )}
          >
            <div
              className={`flex-row ${"justify-between"} items-center w-full flex z-10`}
            >
              {!router.query.hideMenu && (
                <div className="flex items-center gap-8">
                  <AnimatePresence>
                    {isMobileAppView && router.pathname !== "/home" && (
                      <motion.button
                        key={"back"}
                        initial={{ opacity: 0, translateX: -100 }}
                        animate={{ opacity: 1, translateX: 0 }}
                        exit={{ opacity: 0, translateX: -100 }}
                        onClick={() => router.back()}
                        className={`flex lg:ml-14  ${
                          isLandscape ? "" : "ml-8"
                        } items-centerjustify-center bg-none rounded-full text-white`}
                      >
                        <IconArrowLeft className="w-8" />
                      </motion.button>
                    )}
                  </AnimatePresence>
                  {!(isMobileAppView && router.pathname !== "/home") && (
                    <Link passHref href="/home">
                      <motion.div
                        layout
                        className={`${
                          isLandscape ? "" : "ml-8"
                        } cursor-pointer lg:ml-14 mr-8`}
                      >
                        <LongLogo />
                      </motion.div>
                    </Link>
                  )}
                  {isMobileAppView && router.pathname.includes("profiles") && (
                    <div>{trainerQuery.data?.name}</div>
                  )}
                  {!mobileView && !isMobileAppView && (
                    <div className="hidden md:flex gap-8 items-center">
                      {trainerUsername && trainerQuery.data?.logoImagePaths && (
                        <Link href={`/profiles/${trainerQuery.data.slug}`}>
                          <a>
                            <LazyLoadedImg
                              transparent
                              className="w-10 h-10"
                              imgClassName="object-contain"
                              srcSet={trainerQuery.data.logoImagePaths}
                            />
                          </a>
                        </Link>
                      )}

                      {router.pathname !== "/" &&
                        navLinks.map((btn, i) => (
                          <div
                            key={btn.label + i}
                            className={`${i == active ? "text-primary" : ""}`}
                          >
                            <Link href={btn.href}>{btn.label}</Link>
                          </div>
                        ))}
                      {router.pathname !== "/" &&
                        !trainerUsername &&
                        categories.data && (
                          <div
                            className="flex gap-2 items-center cursor-pointer hover:text-primary-hover"
                            onClick={() => setCategoriesOpen(!categoriesOpen)}
                          >
                            <span>
                              {translate("common:m-layout.categories")}
                            </span>
                            {categoriesOpen ? (
                              <IconChevronUp className="w-4" />
                            ) : (
                              <IconChevronDown className="w-4" />
                            )}
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}

              <div
                className={`flex justify-start ${
                  router.query.hideMenu && "ml-auto"
                } items-center gap-2`}
              >
                {!mobileView && !router.query.hideMenu && <LanguagePicker />}
                {router.pathname !== "/" &&
                  !router.query.hideMenu &&
                  !mobileView && (
                    <Search
                      focused={searchFocused}
                      setFocused={setSearchFocused}
                    />
                  )}
                <div className="hidden lg:flex items-center gap-4 mr-14">
                  {isLoggedIn && data ? (
                    userStore.cache?.roles.includes("trainer") ? (
                      <>
                        <Link href={`/profiles/${data.slug}`}>
                          <a className="flex items-center gap-4 ">
                            <p>{data.name}</p>
                            <LazyLoadedImg
                              className="w-10 h-10 rounded-lg"
                              srcSet={data.profileImagePaths}
                            />
                          </a>
                        </Link>
                        <ProfileDropdown />
                      </>
                    ) : (
                      <>
                        <div className="flex items-center gap-4 mr-14 ">
                          <p>{data.name}</p>
                          <LazyLoadedImg
                            className="w-10 h-10 rounded-lg"
                            srcSet={data.profileImagePaths}
                          />
                          <ProfileDropdown />
                        </div>
                      </>
                    )
                  ) : (
                    (isLoggedIn === false && router.pathname !== "/" && (
                      <>
                        <Button
                          variant="transparent"
                          size="min"
                          className="w-26 ml-5 h-10"
                          onClick={() => {
                            modalContext.show(LoginFormModal, {
                              normalSignup: router.pathname.includes("home")
                                ? true
                                : !!trainerQuery.data?.id,
                              tab: 1,
                              trainerId,
                            });
                          }}
                        >
                          {translate("log-in")}
                        </Button>
                        <Button
                          size="large"
                          variant="primarybox"
                          className="h-10 mr-7"
                          onClick={() => {
                            modalContext.show(LoginFormModal, {
                              normalSignup: router.pathname.includes("home")
                                ? true
                                : !!trainerQuery.data?.id,
                              tab: 0,
                              trainerId,
                            });
                          }}
                        >
                          {translate("sign-up")}
                        </Button>
                      </>
                    )) ||
                    (isLoggedIn === false && router.pathname === "/" && (
                      <>
                        <Button
                          variant="transparent"
                          size="min"
                          className="w-26 ml-5 mr-7 h-10"
                          onClick={() => {
                            modalContext.show(LoginFormModal, {
                              normalSignup: router.pathname.includes("home")
                                ? true
                                : !!trainerQuery.data?.id,
                              tab: 1,
                              trainerId,
                            });
                          }}
                        >
                          {translate("log-in")}
                        </Button>
                      </>
                    ))
                  )}
                </div>
                <div className="lg:hidden mr-8">
                  <HamburgerMenu
                    trainerId={trainerQuery.data?.id}
                    links={navLinks}
                  />
                </div>
              </div>
            </div>
            <div>
              {categories.data && categoriesOpen && (
                <div
                  className="z-10 grid gap-4 -mt-14 bg-gray-700 w-full h-screen px-16 py-16 overflow-auto"
                  style={{
                    gridTemplateColumns:
                      "repeat( auto-fill, minmax(300px, 1fr) )",
                  }}
                >
                  {categories.data.map((category) => (
                    <div
                      onClick={() => setCategoriesOpen(false)}
                      key={category.id}
                    >
                      <CategoryCard
                        name={category.name}
                        id={category.id}
                        backgroundColor={category.backgroundColor}
                        textColor={category.textColor}
                        srcSet={category.thumbnailImagePaths}
                        viewCount={category.viewCount}
                        size="small"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      <div className={`bg-gray-900`}>{children}</div>
      <div className="flex flex-col mt-auto md:flex-row md:justify-between gap-6 z-50 w-full bg-black border-t border-gray-400 border-opacity-20 px-5 md:px-24 pt-10 pb-36 sm:pb-14">
        {!router.pathname.includes("home") && trainerId ? (
          <>
            <div className="flex gap-10 items-center justify-center md:justify-start pb-3 w-1/2">
              <LazyLoadedImg
                className="w-20 h-20 md:w-20 md:h-20 flex-none rounded-2xl"
                srcSet={trainerQuery.data?.profileImagePaths ?? {}}
              />
              <div className="space-y-0">
                <h2 className="text-lg md:text-4xl leading-7">
                  {trainerQuery.data?.name}
                </h2>
                <div className="flex gap-5">
                  <p className="text-gray-400">
                    {trainerQuery.data?.viewCount ?? 0}{" "}
                    {translate("common:m-layout.viewer", {
                      count: trainerQuery.data?.viewCount ?? 0,
                    })}
                  </p>
                  {/* <p className="text-gray-400">
                    {trainerQuery.data?.subscribersCount ?? 0}{" "}
                    {translate("common:m-layout.trainee", {
                      count: trainerQuery.data?.viewCount ?? 0,
                    })}
                  </p> */}
                  <p className="text-gray-400">
                    {trainerQuery.data?.followersCount ?? 0}{" "}
                    {translate("common:m-layout.fan", {
                      count: trainerQuery.data?.viewCount ?? 0,
                    })}
                  </p>
                </div>
                <StarRating
                  userId={trainerQuery.data?.id}
                  showNumber
                  count={trainerQuery.data?.ratingCount ?? 0}
                  rating={trainerQuery.data?.ratingAvg ?? 0}
                />
                {!isMobileAppView && !mobileView && (
                  <MQView query=">md">
                    <div className="pt-8 flex gap-5 items-center">
                      {trainerQuery.data?.streamerType?.toLowerCase() ===
                      "event" ? (
                        <BuyEventButton
                          price={`${trainerQuery.data.eventTicketPrice}${
                            trainerQuery.data.currency
                              ? trainerQuery.data.currency === "EUR"
                                ? "€"
                                : trainerQuery.data.currency === "USD"
                                ? "$"
                                : trainerQuery.data.currency === "GBP"
                                ? "£"
                                : trainerQuery.data.currency
                              : "€"
                          }`}
                          slug={trainerQuery.data?.slug}
                        ></BuyEventButton>
                      ) : (
                        <SubscribeButton userId={trainerQuery.data?.id ?? ""} />
                      )}
                      <Button
                        variant="circle"
                        size="min"
                        icon={
                          following ? (
                            <IconFavoriteFilled className="w-8" />
                          ) : (
                            <IconFavorite className="w-8" />
                          )
                        }
                        onClick={async () => {
                          if (data) {
                            await apiClient.post(
                              `${
                                following
                                  ? `users/${trainerQuery.data?.id}/unfollow`
                                  : `users/${trainerQuery.data?.id}/follow`
                              }`
                            );
                            setFollowing(!following);
                          } else {
                            modalContext.show(LoginFormModal, {
                              normalSignup: true,
                            });
                          }
                        }}
                      />
                    </div>
                  </MQView>
                )}
              </div>
            </div>
            {(mobileView || isMobileAppView) && (
              <div className="w-full md:w-1/3 flex md:flex-col gap-4 items-center md:items-start justify-center md:justify-between border-b md:pb-0 pb-10 border-gray-400 border-opacity-20 md:border-none">
                {trainerQuery.data?.streamerType?.toLowerCase() === "event" ? (
                  <BuyEventButton
                    price={`${trainerQuery.data.eventTicketPrice}${
                      trainerQuery.data.currency
                        ? trainerQuery.data.currency === "EUR"
                          ? "€"
                          : trainerQuery.data.currency === "USD"
                          ? "$"
                          : trainerQuery.data.currency === "GBP"
                          ? "£"
                          : trainerQuery.data.currency
                        : "€"
                    }`}
                    slug={trainerQuery.data?.slug}
                  ></BuyEventButton>
                ) : (
                  <SubscribeButton userId={trainerQuery.data?.id ?? ""} />
                )}
                {router.pathname.includes("[id]") && (
                  <div className="h-full flex md:flex-row gap-2 items-center md:w-full justify-center md:justify-between">
                    <Button
                      variant="circle"
                      size="min"
                      className="bg-none"
                      icon={
                        router.pathname.endsWith("profiles/[id]") ? (
                          <IconBackToHome />
                        ) : (
                          <IconArrowRight />
                        )
                      }
                      onClick={() => {
                        if (router.pathname.endsWith("profiles/[id]")) {
                          router.push("/home");
                        } else {
                          router.push(`/profiles/${trainerUsername}`);
                        }
                      }}
                    ></Button>
                  </div>
                )}
              </div>
            )}
            <MQView query=">md">
              <div className="w-full md:w-1/2 space-y-6">
                <div className="">
                  <Tags categories={profileTags} />
                </div>
                <p className="pt-2">{trainerQuery.data?.motto}</p>
                <Link href={`/profiles/${trainerUsername}`}>
                  <Button
                    size="min"
                    variant="text"
                    icon={<IconArrowRight />}
                    iconRight
                    className=""
                  >
                    {translate("common:m-layout.visit-profile")}
                  </Button>
                </Link>
              </div>
            </MQView>
            <div className="flex flex-col w-full md:w-1/2">
              <div className="flex flex-row mb-8">
                <Link href="/home">
                  <a>
                    <LongLogo className="" />
                  </a>
                </Link>
                <div className="hidden md:flex ml-auto gap-4">
                  <a
                    className="w-28"
                    href="https://play.google.com/store/apps/details?id=com.sportinlive.android"
                  >
                    <img src="/googleandroid-download.png" alt="" />
                  </a>
                  <a
                    className="w-28"
                    href="https://apps.apple.com/hu/app/sportin-live/id6465792537"
                  >
                    <img src="/appletv-download.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="flex flex-col gap-5 w-full">
                <div className="flex gap-5 w-full">
                  <Link href="https://sportin.helpcenter.io">
                    <a>{translate("common:m-layout.help-center")}</a>
                  </Link>
                  <Link href="/terms" locale={false}>
                    <a className="ml-auto">
                      {translate("common:m-layout.terms-of-service")}
                    </a>
                  </Link>
                </div>
                <div className="flex gap-5 w-full">
                  <Link href="mailto:hello@fitters.live">
                    <a>{translate("common:m-layout.contact-us")}</a>
                  </Link>
                  <Link href="/privacy" locale={false}>
                    <a className="ml-auto">
                      {translate("common:m-layout.privacy-policy")}
                    </a>
                  </Link>
                </div>
                <p className="text-gray-400 bg-black text-right w-full">
                  Fitter Global Ltd. -{" "}
                  {translate("common:m-layout.all-rights-reserved")},{" "}
                  {new Date().getFullYear()}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <Link href="/home">
              <a>
                <LongLogo className="" />
              </a>
            </Link>
            <div
              className={`grid grid-cols-2 gap-4 text-lg md:text-normal w-3/4 justify-around md:mx-auto ${
                router.pathname === "/home" ||
                router.pathname.includes("categories")
                  ? "md:grid-cols-5"
                  : "md:grid-cols-4"
              }`}
            >
              <div className="flex flex-col gap-4">
                <Link href="https://sportin.helpcenter.io">
                  <a>{translate("common:m-layout.help-center")}</a>
                </Link>
                <Link href="mailto:hello@fitters.live">
                  <a>{translate("common:m-layout.contact-us")}</a>
                </Link>
              </div>
              <div className="flex flex-col gap-4">
                <Link href="/green">
                  <a className="text-green-500">
                    {translate("common:m-layout.we-are-green")}!
                  </a>
                </Link>
                <Link href="/startstreaming">
                  <a>{translate("common:m-layout.join-as-a-trainer")}</a>
                </Link>
              </div>
              <div className="flex flex-col gap-4">
                <Link href="/terms" locale={false}>
                  <a>{translate("common:m-layout.terms-of-service")}</a>
                </Link>
                <Link href="/privacy" locale={false}>
                  <a>{translate("common:m-layout.privacy-policy")}</a>
                </Link>
              </div>
              <div className="flex flex-col gap-4">
                <a href="https://www.facebook.com/FittersLive-101245732644047">
                  Facebook
                </a>
                <a href="https://www.instagram.com/fitters.live_worldwide/">
                  Instagram
                </a>
              </div>
              {(router.pathname === "/home" ||
                router.pathname.includes("categories")) && (
                <div className="hidden md:flex flex-col gap-4">
                  <a
                    className="w-28"
                    href="https://play.google.com/store/apps/details?id=com.sportinlive.android"
                  >
                    <img src="/googleandroid-download.png" alt="" />
                  </a>
                  <a
                    className="w-28"
                    href="https://apps.apple.com/hu/app/sportin-live/id6465792537"
                  >
                    <img src="/appletv-download.png" alt="" />
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MainLayout;
