// Returns a debounced version of the provided function
const debounce = (func: (...args: any[]) => void, wait: number, mode: "leading" | "trailing" = "trailing") => {
  let timeoutId: ReturnType<typeof setTimeout> | null;

  const debounced = (...args: any[]) => {
    if (mode === "leading") {
      if (timeoutId) return;

      func.apply(null, args);

      timeoutId = setTimeout(() => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = null;
      }, wait);
    } else {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = null;

      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, wait);
    }
  };

  debounced.cancel = () => {
    if (timeoutId) clearTimeout(timeoutId);
  };

  return debounced;
};

export default debounce;