import {
  DependencyList,
  EffectCallback,
  useEffect,
  useRef,
  useState
} from "react";

const useEffectOnceAllDeps = (effect: EffectCallback, deps: DependencyList) => {
  const ran = useRef<boolean>(false);
  const [cleanup, setCleanup] = useState<() => void>();

  useEffect(() => {
    if (ran.current) return;
    if (deps.every((v) => v !== undefined && v !== null)) {
      ran.current = true;
      setCleanup(() => effect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (cleanup)
      return cleanup;
  }, [cleanup]);
};

export default useEffectOnceAllDeps;