import React from "react";
import { useField } from "formik";

import ListSearch, { ListSearchProps } from "./ListSearch";

const ListSearchField: React.FC<
  Omit<
    ListSearchProps & {
      name: string;
    },
    "index" | "setIndex"
  >
> = (props) => {
  const [field, meta, helpers] = useField(props);
  return (
    <ListSearch
      index={meta.value}
      setIndex={(index: number) => helpers.setValue(index)}
      error={meta.error}
      {...field}
      {...props}
    />
  );
};

export default ListSearchField;
