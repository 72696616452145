import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";

import ModalContext from "./ModalContext";

const ModalRoot = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) return null;

  return (
    <AnimatePresence>
      {modalContext.components.length > 0 &&
        modalContext.components.map((component, i) => {
          const ComponentType = component.type;
          return (
            <motion.div
              onClick={(e) =>
                e.target === e.currentTarget && modalContext.hide()
              }
              key={component.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeOut" }}
              className="z-1000 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            >
              <ComponentType modal={component} {...component.props} />
            </motion.div>
          );
        })}
    </AnimatePresence>
  );
};

export default ModalRoot;
