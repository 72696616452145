import {
  AnimatePresence,
  LayoutGroup,
  motion,
  useAnimation,
} from "framer-motion";
import React, { ReactElement, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Button from "./Button";

type TabProps = {
  title: string;
  children: React.ReactNode;
};

export const Tab: React.FC<TabProps> = ({ title, children }) => {
  return <>{children}</>;
};

type TabListProps = {
  children: ReactElement<TabProps>[];
  targetElement?: HTMLElement;
  className?: string;
  tabIndex?: number;
};

const TabList: React.FC<TabListProps> = ({
  children,
  targetElement,
  className,
  tabIndex = 0,
}) => {
  const [[currentTabIndex, direction], setTabIndex] = useState([tabIndex, 0]);

  const containerRefs = useRef<HTMLDivElement[]>([]);

  const height = useAnimation();
  const startHeightAnim = () =>
    containerRefs.current[currentTabIndex] &&
    height.start({
      height: containerRefs.current[currentTabIndex].offsetHeight,
      transition: { duration: direction === 0 ? 0 : 0.4 },
    });

  const childToRender = React.Children.toArray(children).filter(
    (_, i) => i === currentTabIndex
  )[0];

  const variants = {
    initial: (dir: number) => ({
      x: dir > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    animate: {
      x: "0",
      opacity: 1,
    },
    exit: (dir: number) => ({
      x: dir > 0 ? "-100%" : "100%",
      opacity: 0,
    }),
  };

  const body = (
    <motion.div className="relative overflow-hidden" animate={height}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="absolute inset-x-0"
          onAnimationStart={(variant) => {
            if (variant === "animate") startHeightAnim();
          }}
          ref={(el) => {
            if (el) {
              containerRefs.current[currentTabIndex] = el;
              if (direction === 0) startHeightAnim();
            }
          }}
          key={currentTabIndex}
          custom={direction}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.4 }}
        >
          {childToRender}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );

  return (
    <div>
      <div className={`flex ${className}`}>
        <LayoutGroup>
          {React.Children.map(children, (tab: React.ReactElement, i) => (
            <div>
              <Button
                variant="transparent"
                className={i === currentTabIndex ? "text-primary" : ""}
                onClick={() =>
                  setTabIndex((prev) => [i, Math.sign(i - prev[0])])
                }
              >
                {tab.props.title}
              </Button>
              {i === currentTabIndex && (
                <motion.div
                  layoutId="underline"
                  className="w-full h-1 bg-primary"
                />
              )}
            </div>
          ))}
        </LayoutGroup>
      </div>
      {targetElement ? ReactDOM.createPortal(body, targetElement) : body}
    </div>
  );
};

export default TabList;
