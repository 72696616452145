import { useContext, useRef } from "react";
import AlertContext from "./context/AlertContext";
import IconCopy from "../svg/ic_copy.svg";
import { useTranslation } from "next-i18next";

type ShareLinkProps = {
  link: string;
};

const ShareLink: React.FC<ShareLinkProps> = ({ link }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const alert = useContext(AlertContext);
  const { t: translate } = useTranslation("common");
  return (
    <div className="flex items-center rounded-xl justify-between bg-gray-800 text-gray-400 h-16 px-5 gap-4 w-full">
      <input
        readOnly
        ref={inputRef}
        className="bg-gray-800 flex-grow"
        onFocus={(e) => e.target.select()}
        type="text"
        value={link}
      />
      <IconCopy
        className="cursor-pointer"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.select();
            navigator.clipboard.writeText(inputRef.current.value);
            alert?.success(translate("common:copied-link-to-clipboard") + ".");
          }
        }}
      />
    </div>
  );
};

export default ShareLink;
