import React, { useEffect, useState } from "react";

type GlobalsContextProps = {
  isTouchDevice: boolean;
};

const GlobalsContext = React.createContext<GlobalsContextProps>({
  isTouchDevice: false,
});
GlobalsContext.displayName = "GlobalsContext";

type GlobalsProviderProps = {
  children: React.ReactNode;
};

export const GlobalsProvider: React.FC<GlobalsProviderProps> = ({
  children,
}) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  useEffect(() => {
    const mq = window.matchMedia("(hover: hover) and (pointer: fine)");
    setIsTouchDevice(!mq.matches);
    const changeHandler = (ev: MediaQueryListEvent) =>
      setIsTouchDevice(!ev.matches);
    if (mq) mq.addEventListener("change", changeHandler);

    return () => mq.removeEventListener("change", changeHandler);
  }, []);

  return (
    <GlobalsContext.Provider value={{ isTouchDevice: isTouchDevice }}>
      {children}
    </GlobalsContext.Provider>
  );
};

export default GlobalsContext;
