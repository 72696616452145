import React, {useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import { Form, Formik } from "formik";
import Button from "../Button";
import apiClient, {axiosClient} from "../../api/apiClient";
import {HTTPError} from "ky";
import InputField from "../InputField";
import * as Yup from "yup";
import {StripeCardElementOptions} from "@stripe/stripe-js";
import {useTranslation} from "next-i18next";

// @ts-ignore
const CardElementForm = ({resultFunction, customerId}) => {
    const { t: translate } = useTranslation("common");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const stripe = useStripe();

    const elements = useElements();

    const Schema = Yup.object().shape({
        name: Yup.string().required(translate('common:card-element-form.a-name-is-required') ?? "A name is required."),
    });

    // @ts-ignore
    const handleSubmit = async (values) => {
        setIsSubmitting(true);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            return;
        }

        setError(null);

        // @ts-ignore
        const setupIntent = await (await axiosClient.get('purchases/setup-intent')).data;

        try {
            // @ts-ignore
            const confirmResult = await stripe.confirmCardSetup(setupIntent?.clientSecret, {
                payment_method: {
                    card: cardElement
                },
                // @ts-ignore
                customer: customerId,
                expand: ['payment_method'],
            });

            // @ts-ignore
            const paymentMethod = confirmResult?.setupIntent?.payment_method;

            // @ts-ignore
            if (!paymentMethod?.card) {
                throw new Error(translate('common:card-element-form.invalid-card-details') ?? 'Invalid card details');
            }

            // @ts-ignore
            const cardDetails = paymentMethod.card;

            if (confirmResult?.setupIntent?.status !== 'succeeded') {
                throw new Error(translate('common:card-element-form.setup-intent-not-succeeded') ?? 'Setup intent not succeeded');
            }

            try {
                await apiClient.post("purchases/create-card", {
                    json: {
                        // @ts-ignores
                        stripePaymentMethodId: paymentMethod.id,
                        last4: `${cardDetails.last4}`,
                        expMonth: `${cardDetails.exp_month}`,
                        expYear: `${cardDetails.exp_year}`,
                        name: values.name,
                    },
                });

                setSuccess(true);
            } catch (e) {
                if (e instanceof HTTPError) {
                    throw new Error((await e.response.json()).error.message);
                }
            }

            resultFunction({result: 'success',});
        } catch (err) {
            // @ts-ignore
            setError(err.message);
            resultFunction({
                result: 'error',
                // @ts-ignore
                message: err.message,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const options: StripeCardElementOptions = {
        iconStyle: "solid",
        hidePostalCode: true,
        style: {
            base: {
                fontSize: '14px',
                backgroundColor: 'transparent',
                color: '#fff',
            }
        },
    };

    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{name: ''}}
            onSubmit={handleSubmit}
            validationSchema={Schema}
        >
            <Form>
                <h3 style={{marginBottom: "1rem"}}>{translate("common:card-element-form.new-card-details")}</h3>
                <InputField name="name" style={{marginBottom: "1rem"}} placeholder={translate('common:card-element-form.name-on-card') ?? "Name on card"}/>
                <div style={{height: "42px", borderRadius: "5px", backgroundColor: "#2a2a2a", padding: "12px"}}>
                    <CardElement
                        id="card-element"
                        options={options}
                    />
                </div>
                {error && <div style={{color: 'red'}}>{error}</div>}
                {success && <div style={{color: 'green'}}>{translate("common:card-element-form.card-saved-successfully")}</div>}

                <Button type={"submit"} style={{marginTop: "1rem"}} loading={isSubmitting} disabled={isSubmitting}>
                    {translate("common:card-element-form.save-card")}
                </Button>
            </Form>
        </Formik>
    );
};

export default CardElementForm;
