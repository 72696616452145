import { useEffect, useMemo, useState } from "react";
import debounce from "../debounce";

export default function useDebounce<T>(value: T, delay: number) {
  const [debouncedVal, setDebouncedVal] = useState(value);
  const debounced = useMemo(() => debounce((v) => setDebouncedVal(v), delay), [delay]);

  useEffect(() => {
    debounced(value);
  }, [debounced, value]);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return debouncedVal;
}
