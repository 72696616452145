import WindowModal from "../WindowModal";
import Button from "../Button";
import Modal from "../../types/Modal";
import { useTranslation } from "next-i18next";

type ConfirmationModalProps = {
  modal: Modal;
  action: () => Promise<void>;
  confirmButtonLabel: string;
  title: string;
  description?: string;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modal,
  action,
  confirmButtonLabel,
  title,
  description,
}) => {
  const { t: translate } = useTranslation("common");
  return (
    <WindowModal size="full">
      <div className="max-w-lg flex flex-col items-center text-center p-8 gap-4 ring-1 ring-gray-700 rounded-2xl">
        <div className="flex flex-col gap-2">
          <p>{title}</p>
          {description && <p className="font-bold">{description}</p>}
        </div>
        <div className="flex justify-between gap-8">
          <Button
            size="min"
            awaitOnClick={async () => {
              await action();
              modal.hide();
            }}
            variant="red"
          >
            {confirmButtonLabel}
          </Button>
          <Button variant="secondary" onClick={modal.hide}>
            {translate("common:cancel")}
          </Button>
        </div>
      </div>
    </WindowModal>
  );
};

export default ConfirmationModal;
