import styles from "../styles/LoadingSpinner.module.css";

type LoadingSpinnerProps = {
  size?: "normal" | "large";
  color?: "accent" | "white";
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = "normal",
  color = "accent",
}) => {
  return (
    <div className="w-full flex justify-center">
      <div
        className={`${
          size === "normal" ? "w-6 h-6" : "w-10 h-10"
        } overflow-hidden`}
      >
        <div
          className={`w-full h-full rounded-full ${
            size === "normal" ? "border-3" : "border-4"
          } ${
            color === "accent" ? "text-primary" : "text-white"
          } border-primary border-opacity-30 animate-spin ${styles.inner}`}
        />
      </div>
    </div>
  );
};

export const LoadingSpinnerOverlay: React.FC<LoadingSpinnerProps> = (props) => (
  <div className="absolute inset-0 bg-gray-700 flex items-center justify-center">
    <LoadingSpinner size={props.size} color={props.color} />
  </div>
);

export default LoadingSpinner;
