import preval from "preval.macro";
import { useTranslation } from "next-i18next";
import { useOwnTrainerProfile } from "../../api/hooks";
import useViewportSize from "./useViewportSize";

const screens = preval`
  module.exports = require("tailwindcss/resolveConfig")(require("../../../tailwind.config.js")).theme.screens;
`;

const useUserControlLinks = () => {
  const { data } = useOwnTrainerProfile();
  const { width } = useViewportSize();
  const mobileView = width < parseInt(screens["md"].slice(0, -2));
  const {t:translate} = useTranslation("common")
  let results: { label: string; href: string }[] = [];

  if (data?.id) {
    if (mobileView)
      results.push({ label: translate("common:user-control-links.my-profile"), href: `/profiles/${data.slug}` }, { label: translate("common:user-control-links.streamer-dashboard"), href: "/dashboard/videos" });
    else
      results.push({ label: translate("common:user-control-links.streamer-dashboard"), href: "/dashboard/videos" });
  }

  results.push({ label: translate("common:user-control-links.account-and-profile"), href: "/dashboard" });

  if (!mobileView) {
    results.push(
      {
        label: translate("common:user-control-links.subscriptions-and-purchases"),
        href: "/subscriptions",
      }
    );
  }

  results.push({
    label: translate("common:user-control-links.log-out"),
    href: "/logout",
  });

  return results;
};

export default useUserControlLinks;
