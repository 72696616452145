import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { useContext } from "react";
import AlertContext from "./AlertContext";

const AlertRoot = () => {
  const alert = useContext(AlertContext);

  const alertVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
      y: -60,
    },
    animate: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.2,
        type: "tween",
      },
    },
  };

  return (
    <LayoutGroup>
      <div className="z-1000 fixed top-0 left-1/2 w-full -translate-x-1/2 flex flex-col-reverse items-center mt-6 gap-y-6 select-none pointer-events-none">
        <AnimatePresence>
          {alert?.alerts.map((a) => {
            let col: string;
            switch (a.type) {
              case "error":
                col = "bg-red-800 hover:bg-red-900";
                break;
              case "info":
                col = "bg-yellow-800 hover:bg-yellow-900";
              default:
                col = "bg-green-800 hover:bg-green-900";
                break;
            }

            return (
              <motion.p
                layout
                variants={alertVariants}
                animate="animate"
                initial="initial"
                exit="exit"
                className={`px-12 py-4 transition-colors cursor-pointer pointer-events-auto rounded-xl ${col}`}
                style={{
                  marginTop: "env(safe-area-inset-top)",
                  boxShadow: "0 4px 15px 6px rgba(0,0,0,0.5)",
                }}
                key={a.id}
                onClick={() => alert.remove(a.id)}
              >
                {a.text}
              </motion.p>
            );
          })}
        </AnimatePresence>
      </div>
    </LayoutGroup>
  );
};

export default AlertRoot;
