import { motion } from "framer-motion";
import { useCategories } from "../../../api/hooks";
import CategoryMenuCard from "../../CategoryMenuCard";
import { useTranslation } from "react-i18next";

type HamburgerMenuCategoriesPageProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HamburgerMenuCategoriesPage: React.FC<
  HamburgerMenuCategoriesPageProps
> = ({ setIsOpen }) => {
  const { data } = useCategories();
  const { t: translate } = useTranslation("common");

  if (!data) return null;

  const variants = {
    hidden: { y: -10, opacity: 0 },
    show: { y: 0, opacity: 1 },
  };

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="show"
      transition={{ staggerChildren: 0.03, delay: 0.1 }}
      className="flex flex-col h-full overflow-auto px-3 pb-3 gap-1"
    >
      <h1 className="mb-2">{translate("common:m-layout.categories")}</h1>
      {data.map((category) => (
        <motion.div
          variants={variants}
          onClick={() => setIsOpen(false)}
          key={category.id}
        >
          <CategoryMenuCard
            name={category.name}
            id={category.id}
            backgroundColor={category.backgroundColor}
            srcSet={category.thumbnailImagePaths}
            textColor={category.textColor}
            viewCount={category.viewCount}
          />
        </motion.div>
      ))}
    </motion.div>
  );
};

export default HamburgerMenuCategoriesPage;
