import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import useElementContentRect from "../lib/hooks/useElementContentRect";
import { convertSourceSet, getClosestQualityUrl } from "../lib/imageUtils";
import ImageSourceSet from "../types/ImageSourceSet";

type LazyLoadedImgProps = {
  srcSet: ImageSourceSet | string;
  className?: string;
  imgClassName?: string;
  transparent?: boolean;
};

type ImageAction = {
  type: "reset" | "push_front" | "image_loaded" | "cleanup";
  payload?: ImageState;
};

type ImageState = {
  url: string;
  quality: string;
  ready?: boolean;
};

function imageReducer(state: ImageState[], action: ImageAction) {
  switch (action.type) {
    case "reset":
      return [];
    case "push_front": {
      if (action.payload) return [action.payload, ...state];
    }
    case "image_loaded": {
      if (action.payload) {
        return state.map((image) =>
          image.quality === action.payload!.quality
            ? { ...image, ready: true }
            : image
        );
      }
    }
    case "cleanup": {
      if (state.length > 1) {
        const bestImageIndex = state.findIndex((image) => image.ready);
        const newState = state.filter((_, i) => i <= bestImageIndex);
        if (newState.length < state.length) return newState;
      }
    }
  }
  return state;
}

const LazyLoadedImg: React.FC<LazyLoadedImgProps> = ({
  srcSet,
  className = "",
  imgClassName = "",
  transparent = false,
}) => {
  const ref = useRef<HTMLImageElement | null>(null);
  const { width, height, x, y } = useElementContentRect(ref);
  if ((x !== 0 || y !== 0) && (width === 0 || height === 0)) {
    console.error("WARNING: LazyLoadedImg with no dimensions");
  }

  const [state, dispatch] = useReducer(imageReducer, [] as ImageState[]);
  const bestImageIndex = useMemo(
    () => state.findIndex((image) => image.ready),
    [state]
  );

  useEffect(() => {
    dispatch({ type: "cleanup" });
  }, [state]);

  const [isOnScreen, setIsOnScreen] = useState(false);

  const convertedSrcSet = useMemo(() => {
    dispatch({ type: "reset" });
    return typeof srcSet === "object" ? convertSourceSet(srcSet) : srcSet;
  }, [srcSet]);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const observer = new IntersectionObserver((entries) => {
      const onScreen = entries[0].isIntersecting;
      setIsOnScreen(onScreen);
    });
    observer.observe(el);

    return () => observer.unobserve(el);
  }, []);

  useEffect(() => {
    if (height === 0) return;

    let step: string | null = null;
    if (typeof convertedSrcSet === "object") {
      const keys = Object.keys(convertedSrcSet);
      if (keys.length === 0) return;

      step =
        keys.find((quality) => height < parseInt(quality)) ??
        keys[keys.length - 1];

      if (
        state[0]?.quality &&
        keys.indexOf(step) <= keys.indexOf(state[0].quality)
      )
        return;
    }
    let url: string | undefined = undefined;
    if (typeof convertedSrcSet === "string") url = convertedSrcSet;
    else if (typeof convertedSrcSet === "object") url = convertedSrcSet[step!];

    dispatch({ type: "push_front", payload: { url: url!, quality: step! } });
  }, [height, convertedSrcSet]);
  return (
    <div
      ref={ref}
      className={`overflow-hidden isolate ${
        transparent ? "" : "bg-gray-700 "
      }${className}`}
    >
      <div
        className={`relative w-full h-full ${
          bestImageIndex === -1 ? "blur-sm" : ""
        }`}
      >
        {[...state].reverse().map((image) => (
          <img
            decoding="async"
            className={`absolute h-full ${
              image.ready ? "" : "blur-sm"
            } ${imgClassName}`}
            key={image.quality}
            src={image.url}
            onLoad={() => dispatch({ type: "image_loaded", payload: image })}
          />
        ))}
      </div>
    </div>
  );
};
export default LazyLoadedImg;
