import { AnimatePresence, motion } from "framer-motion";

import IconClose from "../../svg/ic_close.svg";
import IconArrowLeft from "../../svg/ic_arrow_left.svg";
import { HamburgerMenuPage } from "./HamburgerMenu";

type HamburgerMenuHeaderProps = {
  page: HamburgerMenuPage;
  setPage: React.Dispatch<React.SetStateAction<HamburgerMenuPage>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HamburgerMenuHeader: React.FC<HamburgerMenuHeaderProps> = ({
  page,
  setPage,
  setIsOpen,
}) => {
  return (
    <div className="flex items-center border-b border-b-gray-700 mb-6">
      <AnimatePresence>
        {page !== "main" && (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.1 }}
            className="w-20 h-20 flex items-center justify-center cursor-pointer"
            onClick={() => setPage("main")}
          >
            <IconArrowLeft />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className="w-20 h-20 ml-auto flex items-center justify-center cursor-pointer"
        onClick={() => setIsOpen(false)}
      >
        <IconClose />
      </div>
    </div>
  );
};

export default HamburgerMenuHeader;
