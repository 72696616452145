const IconRadioButton = ({ selected = false }: { selected: boolean }) => {
  return (
    <svg className="w-6 h-6" viewBox="0 0 18 18">
      <g className={selected ? "text-primary" : "text-white"}>
        <path
          d="M9,18a9,9,0,1,1,9-9A9,9,0,0,1,9,18ZM9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2Z"
          fill="currentColor"
        />
        <circle
          className={selected ? "selected" : ""}
          cx="9"
          cy="9"
          r="5"
          fill="currentColor"
        />
        <style jsx>{`
          path {
            transition: color 0.15s;
          }
          circle {
            transform-origin: center;
            transform: scale(0.2);
            opacity: 0;
            transition: color 0.15s, transform 0.15s, opacity 0.15s;
          }
          .selected {
            transform: scale(1);
            opacity: 1;
          }
        `}</style>
      </g>
    </svg>
  );
};

export default IconRadioButton;
