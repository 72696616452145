import React, { forwardRef } from "react";
import styles from "../styles/Input.module.css";

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  label?: string;
  error?: string;
  multiline?: boolean;
  rows?: number;
  innerClassName?: string;
  currency?: boolean;
  currencyType?: string;
  min?: number;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    className = "",
    innerClassName = "",
    label,
    error,
    multiline,
    currency,
    currencyType = "EUR",
    min,
    ...props
  },
  ref
) {
  const ring = error ? "ring-1 ring-crimson" : "focus:ring-1 ring-primary";
  const inputClass = `p-4 resize-none rounded-xl placeholder-gray-400 disabled:placeholder-opacity-25 bg-gray-700 transition duration-150 ${ring} ${innerClassName}`;

  const el = multiline ? (
    <textarea ref={ref as any} className={inputClass} {...(props as any)} />
  ) : currency ? (
    <div className="flex items-center bg-gray-700 rounded-2xl w-min pr-4">
      <input min={min} ref={ref} className={inputClass} {...props} />
      <p className="text-gray-400 pl-2">{currencyType}</p>
    </div>
  ) : (
    <input ref={ref} className={inputClass} {...props} />
  );
  return (
    <div className={`flex flex-col-reverse ${className}`}>
      {el}
      {label && (
        <p
          className={`${styles.label} mb-2 ${
            error ? "text-crimson" : "text-gray-400"
          } transition-colors duration-150`}
        >
          {error ?? label}
        </p>
      )}
    </div>
  );
});

export default Input;
