import { motion } from "framer-motion";
import { useState } from "react";

type ToggleButtonProps = {
  initialState: boolean;
  onClick: (state: boolean) => void;
};
type ToggleButtonOverrideProps = {
  state: boolean;
  setState: (state: boolean) => void;
};

const ToggleButton: React.FC<ToggleButtonProps | ToggleButtonOverrideProps> = (
  props
) => {
  let state: boolean, setState: (state: boolean) => void;
  if (!("setState" in props)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [state, setState] = useState(props.initialState);
  } else {
    ({ state, setState } = props);
  }

  return (
    <motion.div
      whileTap="tap"
      className={`flex ${
        state ? "justify-end" : "justify-start"
      } w-12 p-1 rounded-full bg-gray-700 cursor-pointer`}
      onClick={() => {
        if ("onClick" in props) props.onClick(!state);
        setState(!state);
      }}
    >
      <motion.div
        layout
        variants={{ tap: { scale: 0.8 } }}
        transition={{ type: "spring", stiffness: 700, damping: 30 }}
        className={`w-5 h-5 rounded-full ${
          state ? "bg-primary" : "bg-gray-400"
        } transition-colors`}
      ></motion.div>
    </motion.div>
  );
};

export default ToggleButton;
