import IconRadioButton from "./icons/IconRadioButton";

export type RadioButtonProps = {
  buttons: string[];
  index: number;
  setIndex: (index: number) => void;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  buttons,
  index,
  setIndex,
}) => {
  return (
    <div className="flex space-x-4">
      {buttons.map((button, i) => (
        <div
          className="flex space-x-2"
          onClick={() => setIndex(i)}
          key={`radio${i}`}
        >
          <IconRadioButton selected={index === i} />
          <div className="rounded-xl text-gray-400">{button}</div>
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
