import { useField } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo } from "react";
import { useTranslation } from "next-i18next";
import ImageSourceSet from "../types/ImageSourceSet";
import ThumbnailPicker from "./ThumbnailPicker";
import VideoUploader, { VideoProps, VideoUploaderProps } from "./VideoUploader";

export type VidAndThumb = {
  video: VideoProps | null;
  thumbnail: File | null;
};

const VidAndThumbField: React.FC<
  Omit<
    VideoUploaderProps & {
      name: string;
      initialThumbnailImagePath?: ImageSourceSet;
    },
    "onStatusChange"
  >
> = ({ initialThumbnailImagePath, ...props }) => {
  const { t: translate } = useTranslation("common");
  const [field, meta, helpers] = useField<VidAndThumb>(props);
  const blob = useMemo(
    () => meta.value?.thumbnail && URL.createObjectURL(meta.value.thumbnail),
    [meta.value?.thumbnail]
  );

  return (
    <div className="flex flex-col gap-2">
      <VideoUploader
        {...props}
        {...field}
        onStatusChange={(_status, res) =>
          helpers.setValue({ video: res, thumbnail: null })
        }
        className={meta.error && "border border-crimson"}
        postUrl="videos/upload-file"
      >
        {meta.value?.video ? (
          <div
            className="absolute inset-0 flex items-center justify-center opacity-70"
            style={{
              background:
                "repeating-conic-gradient(#333 0% 25%, transparent 0% 50%) 50% / 20px 20px",
            }}
          >
            {meta.value.thumbnail ? (
              <div
                className="absolute inset-0 bg-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage: `url(${blob})`,
                }}
              />
            ) : (
              translate("common:no-thumbnail")
            )}
            <div className="absolute inset-0 flex items-center justify-center hover:opacity-100 opacity-0 transition bg-gray-700">
              {translate("common:pick-a-different-video")}
            </div>
          </div>
        ) : (
          <p>Add video</p>
        )}
      </VideoUploader>
      <AnimatePresence>
        {meta.value?.video && (
          <motion.div
            className="relative w-full mt-2"
            initial={{ opacity: 0, top: -10 }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0, top: -10 }}
          >
            <ThumbnailPicker
              value={meta.value.thumbnail}
              initialUrl={initialThumbnailImagePath}
              setValue={(value) =>
                helpers.setValue({ ...meta.value, thumbnail: value })
              }
            />
          </motion.div>
        )}
      </AnimatePresence>
      {meta.error && <p className="text-crimson text-center">{meta.error}</p>}
    </div>
  );
};

export default VidAndThumbField;
