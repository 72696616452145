import create from "zustand";
import { persist } from "zustand/middleware";

type ConsentStoreData = {
  analytical: boolean;
  ads: boolean;
  accepted: boolean;
  setAccepted: () => void;
  setAnalytical: (analytical: boolean) => void;
  setAds: (ads: boolean) => void;
}

const useConsentStore = create<ConsentStoreData>(
  persist(
    (set, _get) => ({
      analytical: false,
      ads: false,
      accepted: false,
      setAnalytical: (value: boolean) => set({ analytical: value }),
      setAds: (value) => set({ ads: value }),
      setAccepted: () => set({ accepted: true })
    }),
    { name: "ConsentStore" }
  )
);

export default useConsentStore;