import { Dispatch, SetStateAction, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";

import IconChevronDown from "../svg/ic_chevron_down.svg";
import IconChevronUp from "../svg/ic_chevron_up.svg";

export type MultiSelectListBoxProps = {
  className?: string;
  error?: string;
  label: string;
  placeholder: string;
  items: string[];
  selectedIndices: number[];
  setSelectedIndices: Dispatch<number[]>;
};

const MultiSelectListBox: React.FC<MultiSelectListBoxProps> = ({
  className,
  error,
  label,
  placeholder,
  items,
  selectedIndices,
  setSelectedIndices,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  const addItem = (index: number) =>
    !selectedIndices.includes(index) &&
    setSelectedIndices([...selectedIndices, index]);
  const removeItem = (index: number) =>
    setSelectedIndices(selectedIndices.filter((item) => item !== index));

  return (
    <div
      className={`relative flex flex-col ${className} cursor-pointer`}
      ref={ref}
    >
      <p className={`mb-2 ${error ? "text-crimson" : "text-gray-400"}`}>
        {error ?? label}
      </p>
      <div
        className="flex rounded-xl items-center p-4 bg-gray-700 text-gray-400"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedIndices === null
          ? placeholder
          : selectedIndices.map((index, i) => {
              if (i != selectedIndices.length - 1) {
                return items[index] + ", ";
              } else return items[index];
            })}
        {isOpen ? (
          <IconChevronUp className="ml-auto w-4 h-4" />
        ) : (
          <IconChevronDown className="ml-auto w-4 h-4" />
        )}
      </div>
      <div
        className={`absolute z-1000 right-0 top-24 w-48 max-h-96 overflow-hidden rounded-xl  ${
          isOpen ? "scale-100" : "scale-95 opacity-0 pointer-events-none"
        } transition duration-100`}
      >
        <ul className="max-h-96 border border-gray-900 bg-gray-700 overflow-auto">
          {items.map((item, i) => (
            <li
              key={`listitem${i}`}
              className={`pl-6 py-3 hover:bg-gray-400 hover:bg-opacity-40 ${
                selectedIndices.includes(i) && "bg-gray-400 bg-opacity-60"
              }`}
              onClick={() => {
                selectedIndices.includes(i) ? removeItem(i) : addItem(i);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MultiSelectListBox;
