type TagsProps = {
  categories: string[];
  center?: boolean;
};

const Tags: React.FC<TagsProps> = ({ categories, center = true }) => {
  return (
    <div
      className={`flex flex-wrap ${
        center ? "justify-center md:justify-start " : ""
      }gap-4`}
    >
      {categories &&
        categories.map((category, i) => (
          <div key={i} className="bg-gray-700 rounded-2xl px-4 py-2">
            {category}
          </div>
        ))}
    </div>
  );
};

export default Tags;
