import Link from "next/link";
import useMobileAppStore from "../lib/hooks/useMobileAppStore";
import ImageSourceSet from "../types/ImageSourceSet";
import LazyLoadedImg from "./LazyLoadedImg";
import FixedRatio from "./FixedRatio";

type CategoryMenuCardProps = {
  name: string;
  id: string;
  backgroundColor?: string;
  textColor?: string;
  viewCount?: number;
  size?: "small" | "card";
  srcSet?: ImageSourceSet;
};

const CategoryMenuCard: React.FC<CategoryMenuCardProps> = ({
  name,
  id,
  backgroundColor = "",
  textColor = "",
  viewCount = "",
  srcSet,
}) => {
  const { isMobileAppView } = useMobileAppStore();

  return (
    <Link href={`/categories/${id}`}>
      <FixedRatio
        ratio={16 / 9}
        className={`group flex-none border-solid border-2 border-transparent flex transition duration-[250ms] ease-in-out ${
          !isMobileAppView &&
          "hover:border-white hover:transition hover:scale-[103%]"
        } rounded-xl overflow-hidden cursor-pointer`}
      >
        <div className="relative flex rounded-xl h-full w-full">
          {srcSet && (
            <>
              <LazyLoadedImg
                className="w-full h-full rounded-xl absolute"
                srcSet={srcSet ?? {}}
              />
            </>
          )}
          <p
            className={` ${
              !isMobileAppView && "drop-shadow-category-card-text"
            } leading-[1] absolute bottom-3 left-3 transition duration-[250ms] ease-in-out-transform z-50 text-xl`}
          >
            {name.toLocaleUpperCase()}
          </p>
          <div
            className={`w-full h-full absolute z-40 bg-gray-900 bg-opacity-10 backdrop-saturate-50 transition duration-[250ms] ease-in-out shadow-category-card-inner  ${
              !isMobileAppView &&
              "group-hover:backdrop-saturate-100 backdrop-blur-xs group-hover:bg-opacity-0 group-hover:backdrop-blur-none group-hover:transition group-hover:shadow-none"
            } rounded-lg`}
          ></div>
        </div>
      </FixedRatio>
    </Link>
  );
};

export default CategoryMenuCard;
