import { motion } from "framer-motion";

type AnimatedTapProps = {
  children: React.ReactNode;
  className?: string;
};

const AnimatedTap: React.FC<AnimatedTapProps> = ({ children, className }) => {
  return (
    <motion.div
      variants={{
        tap: {
          scale: 0.95,
          backgroundColor: "#333",
          transition: { duration: 0.1 },
        },
      }}
      whileTap="tap"
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedTap;
