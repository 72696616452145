import React, { useEffect, useState } from "react";
import Modal from "../../types/Modal";

export type ModalContextProps = {
  components: Modal[];
  show: <T extends React.ComponentType<any>>(
    component: T,
    props: Omit<React.ComponentProps<T>, "modal">,
    onHide?: () => void
  ) => void;
  hide: (modalID?: number) => void;
};

const ModalContext = React.createContext<ModalContextProps>({
  components: [],
  show: () => {},
  hide: () => {},
});
ModalContext.displayName = "ModalContext";

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [[components, _modalIDCounter], setComponentData] = useState<
    [Modal[], number]
  >([[], 0]);

  const hide = (modalID?: number) => {
    setComponentData(([prevComponents, prevModalIDCounter]) => {
      const componentToRemove = prevComponents.find((component, i) =>
        modalID === undefined
          ? i === prevComponents.length - 1
          : component.id === modalID
      );

      componentToRemove?.onHide?.();

      return [
        prevComponents.filter((component) => component !== componentToRemove),
        prevModalIDCounter,
      ];
    });
  };

  useEffect(() => {
    const callback = (_event: PopStateEvent) => hide();
    window.addEventListener("popstate", callback);
    return () => {
      window.removeEventListener("popstate", callback);
    };
  }, []);

  return (
    <ModalContext.Provider
      value={{
        components: components,
        show: (component, props, onHide) => {
          setComponentData(([prevComponents, prevModalIDCounter]) => {
            window.history.pushState(["modal", prevModalIDCounter], "");

            return [
              [
                ...prevComponents,
                {
                  id: prevModalIDCounter,
                  type: component,
                  props: props,
                  hide: () => hide(prevModalIDCounter),
                  onHide: onHide,
                },
              ],
              prevModalIDCounter + 1,
            ];
          });
        },
        hide: hide,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
