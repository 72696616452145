import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import IconChevronDown from "../svg/ic_chevron_down.svg";
import IconChevronUp from "../svg/ic_chevron_up.svg";
import MultiSelectListBox from "./MultiSelectListBox";
const LanguagePicker = () => {
  const router = useRouter();
  const [selectOpen, setSelectOpen] = useState(false);
  useEffect(() => {
    const savedLocale = localStorage.getItem("locale");
    if (router.locale === savedLocale || router.query.app === undefined) return;
    if (savedLocale === "hu") {
      router.push("/hu/home");
    } else {
      router.push("/home");
    }
  }, [router]);
  const setCookie = (locale: string) => {
    document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
  };
  return (
    <div
      onClick={() => setSelectOpen(!selectOpen)}
      className="cursor-pointer relative"
    >
      <div className="gap-2 items-center group flex">
        <span className="group-hover:text-primary-hover">
          {router.locale?.toLocaleUpperCase()}
        </span>
        {selectOpen ? (
          <IconChevronUp className="w-4 group-hover:text-primary-hover" />
        ) : (
          <IconChevronDown className="w-4 group-hover:text-primary-hover" />
        )}
      </div>
      <ul
        className={`absolute bg-gray-900 rounded-lg bg-opacity-75 py-1 px-3 right-0 top-6 flex-col gap-3 ${
          selectOpen ? "flex" : "hidden"
        }`}
      >
        {router.locales?.map((locale) => (
          <li
            onClick={() => setSelectOpen(false)}
            className="select-none hover:text-primary-hover"
            key={locale}
          >
            <button
              onClick={() => {
                router.push(router.asPath, undefined, { locale });
                setCookie(locale);
                localStorage.setItem("locale", locale);
              }}
            >
              {locale.toLocaleUpperCase()}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguagePicker;
