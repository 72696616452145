export const formatTime = (
  totalSeconds: number,
  humanReadable: boolean = false
): string => {
  const suffixes = ["seconds", "minutes", "hours", "days"];

  totalSeconds = Math.floor(totalSeconds);
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor(totalSeconds / 3600) % 24;
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;

  const parts = [days, hours, minutes, seconds];

  // Remove leading parts that are 0 (keeping minutes and seconds always)
  while (parts[0] === 0 && parts.length > 2) parts.shift();

  return parts
    .reverse()
    .map((val, i) =>
      humanReadable
        ? `${val} ${suffixes[i]}` // Add suffix
        : i < 2 && val < 10 // Pad minutes and seconds with a 0
        ? "0" + val
        : val
    )
    .reverse()
    .join(humanReadable ? ", " : ":");
};

export const setTimeToMin = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const setTimeToMax = (date: Date): Date => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

export const getStartOfWeek = (date: Date): Date => {
  const start = new Date(date);

  const day = (((date.getDay() - 1) % 7) + 7) % 7;
  if (day !== 0) start.setHours(-24 * day);

  return setTimeToMin(start);
};

export const convertDate = (date: string | Date | number, showYear = true): string => {
  if (!showYear) {
    return new Date(date).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });
  }

  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getHoursMinutes = (date: string): string => {
  const newDate = new Date(date);
  const hours =
    newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
  const mins =
    newDate.getMinutes() < 10
      ? "0" + newDate.getMinutes()
      : newDate.getMinutes();

  const formatted = hours + ":" + mins;

  return formatted;
};

const timeUnits = ["ms", "sec", "min", "hour", "day"] as const;
type TimeUnit = typeof timeUnits[number];
const steps = [1000, 60, 60, 24];
export const tUnitConv = (
  time: number,
  from: TimeUnit,
  to: TimeUnit
): number => {
  const fromIndex = timeUnits.indexOf(from);
  const toIndex = timeUnits.indexOf(to);

  let convertedTime = time;
  let stepsNeeded = fromIndex - toIndex;
  while (stepsNeeded !== 0) {
    const step = steps[toIndex + stepsNeeded - (stepsNeeded > 0 ? 1 : 0)];

    if (stepsNeeded > 0) convertedTime *= step;
    else convertedTime /= step;

    stepsNeeded += stepsNeeded < 0 ? 1 : -1;
  }

  return convertedTime;
};
