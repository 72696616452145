import { HTTPError } from "ky";
import { useRouter } from "next/router";
import { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import apiClient from "../../api/apiClient";
import { useSelf } from "../../api/hooks";
import { getTrainerByID } from "../../api/wrapper";
import AlertContext from "../../components/context/AlertContext";
import IconClose from "../../svg/ic_close.svg";
import ImageRectangle from "../../svg/other/img_rectangle.svg";
import Modal from "../../types/Modal";
import Button from "../Button";

import { useTranslation } from "next-i18next";
import FullscreenModal from "../FullscreenModal";
import LazyLoadedImg from "../LazyLoadedImg";
import ModalContext from "../context/ModalContext";
import PaymentModal from "./PaymentModal";
import { formatNumberWithSpaces } from "../../lib/stringUtils";

type SubscribeModalProps = {
  modal: Modal;
  userId: any;
  subscribed?: boolean;
};

type SubscriptionPlanProps = {
  typeTitle: string;
  type: string;
  unit: string;
  interval: string;
  price?: number;
  currency?: string;
  subscribed?: boolean;
  recommended?: boolean;
  action: () => Promise<void>;
  trainerId: string;
  stripeAccountId: string;
  hasCard: boolean;
};

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  typeTitle,
  type,
  unit,
  interval,
  price = 0,
  currency,
  subscribed = false,
  recommended = false,
  action,
  stripeAccountId,
  hasCard,
  trainerId,
}) => {
  const { t: translate } = useTranslation("common");
  const modalContext = useContext(ModalContext);

  return (
    <div
      className={`relative flex flex-col ${
        recommended ? "ring-4 ring-primary" : ""
      } bg-gray-700 rounded-xl p-8 gap-6`}
    >
      {recommended && (
        <div className="absolute inset-x-0 bottom-full px-4">
          <p className="text-center bg-primary rounded-t-xl p-2">
            {translate("common:subscribe-modal.recommended-plan")}
          </p>
        </div>
      )}
      <div>
        <p className="text-lg capitalize">{typeTitle}</p>
        <p className="text-gray-400">
          {translate("common:subscribe-modal.all-videos-and-streams-included")}
        </p>
      </div>
      <div className="flex items-end">
        <p className="text-xl leading-none">
          {currency
            ? currency === "EUR"
              ? "€"
              : currency === "USD"
              ? "$"
              : currency === "GBP"
              ? "£"
              : `${currency} `
            : "€"}
          {formatNumberWithSpaces(price)}
        </p>
        <p className="ml-2 text-gray-400 text-normal">/{unit}</p>
      </div>
      {hasCard && (
        <Button
          variant={recommended ? "primary" : "secondary"}
          disabled={subscribed}
          size="full"
          awaitOnClick={action}
        >
          {subscribed
            ? translate("common:subscribe-modal.subscription-active")
            : translate("common:subscribe-modal.subscribe")}
        </Button>
      )}
      {!hasCard && !subscribed && (
        <Button
          variant={recommended ? "primary" : "secondary"}
          disabled={subscribed}
          size="full"
          onClick={() => {
            modalContext.show(PaymentModal, {
              type:
                type === "monthly"
                  ? "monthlySubscription"
                  : "yearlySubscription",
              itemId: trainerId,
              stripeAccount: stripeAccountId,
            });
          }}
        >
          {translate("common:subscribe-modal.subscribe")}
        </Button>
      )}

      <div>
        <p className="text-gray-400">
          {translate("common:subscribe-modal.renews")} {interval}.
        </p>
        <p className="text-gray-400">
          {translate("common:subscribe-modal.cancel-anytime")}
        </p>
      </div>
    </div>
  );
};

const SubscribeModal: React.FC<SubscribeModalProps> = ({
  modal,
  userId,
  subscribed,
}) => {
  const queryClient = useQueryClient();
  const { isLoading, error, data } = useQuery(["trainer", { id: userId }], () =>
    getTrainerByID(userId)
  );
  const { t: translate } = useTranslation("common");
  const alert = useContext(AlertContext);
  const self = useSelf();
  const router = useRouter();

  const modalContext = useContext(ModalContext);

  if (!data || !self.data) return null;

  const defaultCard = self.data.cards.find((card) => card.default === true);

  const subscribe = async (length: "month" | "year") => {
    if (defaultCard != undefined) {
      try {
        let result;

        if (self.data.companyName)
          result = await apiClient.post(`purchases/subscription/${userId}`, {
            json: {
              cardId: defaultCard?.id,
              subscriptionInterval: length,
              companyReceipt: true,
              returnUrl: window.location.href,
            },
          });
        else
          result = await apiClient.post(`purchases/subscription/${userId}`, {
            json: {
              cardId: defaultCard?.id,
              subscriptionInterval: length,
              returnUrl: window.location.href,
            },
          });

        const resultJson = await result.json();

        if (resultJson.redirectUrl) {
          window.location.href = resultJson.redirectUrl;
          return;
        }

        if (resultJson.error) {
          alert?.error(`Payment failed: ${resultJson.error.message}`);
          return;
        }

        setTimeout(async () => {
          alert?.success(translate("common:subscribe-modal.payment-succeeded"));
          await queryClient.refetchQueries([
            "userSubscriptions",
            "trainer",
            userId,
          ]);
          modal.hide();
        }, 3000);
      } catch (err) {
        if (err instanceof HTTPError) {
          const response = await err.response.json();
          if (response.error)
            alert?.error(
              `${translate("common:subscribe-modal.payment-failed")}: ${
                response.error.message
              }`
            );
          else
            alert?.error(
              `${translate("common:subscribe-modal.payment-failed")}: ${
                response.details
              }`
            );
        }
      }
    } else {
      alert?.error(
        translate("common:subscribe-modal.you-need-to-add-a-credit-card-first")
      );
      await router.push("/dashboard");
      modal.hide();
    }
  };

  return (
    <FullscreenModal>
      <Button
        className="z-1000 ml-auto px-12 py-8"
        variant="text"
        size="min"
        onClick={modal.hide}
        icon={<IconClose />}
      >
        {translate("common:cancel")}
      </Button>

      <div className="relative">
        <ImageRectangle className="absolute w-full px-4 h-128 top-0 inset-x-0" />
        <div className="relative flex flex-col items-center gap-10 p-16">
          <LazyLoadedImg
            className="mt-20 h-32 w-32 rounded-3xl"
            srcSet={data.profileImagePaths}
          />
          <div className="text-center">
            <h2 className="mb-1">
              {translate("common:subscribe-modal.subscribe-to-person", {
                person: data.name,
              })}
            </h2>
            <p>{translate("common:subscribe-modal.show-your-love")}</p>
          </div>
          <div className="pt-20 flex flex-col md:flex-row justify-center w-full gap-20">
            <SubscriptionPlan
              typeTitle={translate("common:subscribe-modal.monthly-type")}
              type="monthly"
              unit={translate("common:subscribe-modal.month-unit")}
              interval={translate("common:subscribe-modal.monthly")}
              currency={data.currency}
              price={data.subscriptionPriceMonthly}
              subscribed={subscribed}
              action={() => subscribe("month")}
              hasCard={!!defaultCard}
              trainerId={data.id}
              stripeAccountId={data.stripeAccountId}
            />
            <SubscriptionPlan
              typeTitle={translate("common:subscribe-modal.annual-type")}
              type="yearly"
              unit={translate("common:subscribe-modal.year-unit")}
              interval={translate("common:subscribe-modal.annually")}
              currency={data.currency}
              price={data.subscriptionPriceYearly}
              subscribed={subscribed}
              recommended
              action={() => subscribe("year")}
              hasCard={!!defaultCard}
              trainerId={data.id}
              stripeAccountId={data.stripeAccountId}
            />
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

export default SubscribeModal;
