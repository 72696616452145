import React from "react";
import { useField } from "formik";

import Input, { InputProps } from "./Input";

const InputField: React.FC<
  InputProps & {
    name: string;
  }
> = (props) => {
  const [field, meta] = useField(props);

  return <Input error={meta.error} {...field} {...props} />;
};

export default InputField;
