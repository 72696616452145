import create, { SetState } from "zustand";
import { persist } from "zustand/middleware";
import apiClient from "../../api/apiClient";
import JWTUtils from "../JWTUtils";

export type TokenUserCache = {
  id: string | null;
  username: string | null;
  email: string | null;
  roles: string[];
};

type TokenState = {
  accessToken: string | null;
  cache: TokenUserCache | null;
  setAccessToken: (token: string | null) => void;
  refreshLogin: () => void;
};

const setAccessToken = (set: SetState<TokenState>, token: string | null) => {
  if (token) {
    const payload = JWTUtils.decodePayload(token);
    set({
      accessToken: token,
      cache: {
        id: payload.id,
        username: payload.name,
        email: payload.email,
        roles: payload.roles,
      },
    });
  } else {
    set({
      accessToken: null,
      cache: null,
    });
  }
};

const useUserStore = create<TokenState>(
  persist(
    (set, _get) => ({
      accessToken: null,
      cache: null,
      setAccessToken: (token) => setAccessToken(set, token),
      refreshLogin: async () =>
        setAccessToken(
          set,
          (
            await apiClient
              .post("users/refresh-login")
              .json<{ token: string }>()
          ).token
        ),
    }),
    {
      name: "UserStore",
    }
  )
);

const oldGetState = useUserStore.getState;

useUserStore.getState = () => {
  const state = oldGetState();

  if (state.accessToken && JWTUtils.hasExpired(state.accessToken)) {
    state.setAccessToken(null);
    return oldGetState();
  }

  return state;
};

export default useUserStore;
