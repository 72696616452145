import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { apiPrefixUrl, baseUrl } from "../lib/constants";
import { truncate } from "../lib/stringUtils";

type OgImage = {
  url: string;
  type?: string;
  width?: number;
  height?: number;
};

type OgVideo = {
  url?: string;
  tags?: string[];
  width?: number;
  height?: number;
};

type SeoProps = {
  withOembed?: boolean;
  title?: string;
  description?: string;
  type?: "website" | "profile" | "video.other";
  cardType?: "summary" | "summary_large_image" | "app" | "player";
  image?: OgImage;
  video?: OgVideo;
};

type MetaProps = {
  property: string;
  content?: string;
};

// If content is nullish, return null (if other meta tag exists in the head with same key, it will be removed)
const MetaOverride: React.FC<MetaProps> = ({ property, content }) =>
  content ? <meta property={property} content={content} /> : null;

const Seo: React.FC<SeoProps> = ({
  withOembed = false,
  title,
  description,
  type = "website",
  cardType = "summary",
  image = {
    url: `${baseUrl}/og-logo.jpeg`,
    type: "image/png",
    width: 1200,
    height: 630,
  },
  video,
}) => {
  const router = useRouter();

  const titleTrunc = title ? truncate(title, 40) : "Sportin";
  const descriptionTrunc = description && truncate(description, 160);

  const url = `${baseUrl}${router.asPath}`;

  // NOTE: Keys are added to avoid duplicates
  return (
    <Head>
      <title key="meta_title">
        {title ? `${titleTrunc} - Sportin` : "Sportin"}
      </title>
      {withOembed && (
        <link
          rel="alternate"
          type="application/json+oembed"
          href={`${apiPrefixUrl}oembed?url=${encodeURIComponent(url)}`}
          title={titleTrunc}
        />
      )}
      {/* --- OpenGraph --- */}
      <meta property="og:site_name" content="Sportin" key="og_site_name" />
      <meta property="og:url" content={url} key="og_url" />
      {titleTrunc && (
        <meta property="og:title" content={titleTrunc} key="og_title" />
      )}
      {description && (
        <meta
          property="og:description"
          content={descriptionTrunc}
          key="og_description"
        />
      )}
      {/* Although OpenGraph supports multiple images, only one is needed at a time */}
      {image?.url && (
        <>
          <meta property="og:image" content={image.url} key="og_image" />
          <meta
            property="og:image:secure_url"
            content={image.url}
            key="og_image_sec_url"
          />
          <MetaOverride
            property="og:image:type"
            content={image.type}
            key="og_image_type"
          />
          <MetaOverride
            property="og:image:width"
            content={image.width?.toString()}
            key="og_image_width"
          />
          <MetaOverride
            property="og:image:height"
            content={image.height?.toString()}
            key="og_image_height"
          />
        </>
      )}
      {type && <meta property="og:type" content={type} key="og_type" />}
      {video && (
        <>
          <MetaOverride
            property="og:video"
            content={video.url}
            key="og_video"
          />
          <MetaOverride
            property="og:video:secure_url"
            content={video.url}
            key="og_video_sec_url"
          />
          <MetaOverride
            property="og:video:width"
            content={video.width?.toString()}
            key="og_video_width"
          />
          <MetaOverride
            property="og:video:height"
            content={video.height?.toString()}
            key="og_video_height"
          />
          <meta
            property="og:video:type"
            content="text/html"
            key="og_video_type"
          />
          {video.tags?.map((tag) => (
            <meta property="og:video:tag" content={tag} key={tag} />
          ))}
        </>
      )}
      {/* --- Twitter --- */}
      <meta name="twitter:card" content={cardType} key="tw_card" />
      {titleTrunc && (
        <meta name="twitter:title" content={titleTrunc} key="tw_title" />
      )}
      {descriptionTrunc && (
        <meta
          name="twitter:description"
          content={descriptionTrunc}
          key="tw_description"
        />
      )}
      {image && (
        <meta name="twitter:image" content={image.url} key="tw_image" />
      )}
    </Head>
  );
};

export default Seo;
