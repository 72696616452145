import { motion } from "framer-motion";
import Link from "next/link";
import HamburgerMenuLink from "./HamburgerMenuLink";

type HamburgerMenuLinksProps = {
  links: HamburgerMenuLink[];
  onLinkClicked: () => void;
};

const HamburgerMenuLinks: React.FC<HamburgerMenuLinksProps> = ({
  links,
  onLinkClicked,
}) => {
  return (
    <div className="flex flex-col">
      {links.map((link, i) => {
        const btn = (
          <motion.a
            onClick={onLinkClicked}
            initial={{ y: "-100%", opacity: 0 }}
            animate={{
              y: "0",
              opacity: 1,
              transition: {
                ease: [0.7, 0, 0.2, 0.9],
                duration: 0.2,
                delay: i / 20 + 0.1,
              },
            }}
            whileTap={{
              x: 10,
              transition: { duration: 0.1 },
            }}
            className="block px-12 py-8"
          >
            {link.label}
          </motion.a>
        );

        if (link.href)
          return (
            <Link href={link.href} key={link.label}>
              {btn}
            </Link>
          );
        else if (link.onClick) return btn;
        else return null;
      })}
    </div>
  );
};

export default HamburgerMenuLinks;
