import IconCheck from "../svg/ic_check.svg";

type CheckboxProps = {
  children: React.ReactNode;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  checked,
  setChecked,
  className,
}) => {
  return (
    <div
      className={`${
        className ? className : ""
      }flex items-center gap-3 cursor-pointer group`}
      onClick={() => setChecked(!checked)}
    >
      <div
        className={`flex items-center w-5 h-5 group-hover:bg-gray-400 group-hover:bg-opacity-70 bg-gray-400 bg-opacity-40 ${
          checked ? "" : ""
        }`}
      >
        <IconCheck className={`${checked ? "" : "hidden"} w-5`} />
      </div>
      {children}
    </div>
  );
};
export default Checkbox;
