import { useEffect, useState } from "react";
import debounce from "../debounce";

type Rect = {
  height: number;
  width: number;
  x: number;
  y: number;
};

const useElementContentRect = (
  element: React.RefObject<HTMLElement | null>,
  defaultValue?: Rect
) => {
  const [contentRect, setContentRect] = useState<Rect>(
    defaultValue ?? { height: 0, width: 0, x: 0, y: 0 }
  );

  useEffect(() => {
    const element_ = element.current;
    if (!element_) return;
    setContentRect(element_.getBoundingClientRect());
    const observerCallback = debounce(
      (entries: ResizeObserverEntry[]): void => {
        setContentRect(entries[0].contentRect);
      },
      1000
    );

    const observer = new ResizeObserver(observerCallback);
    observer.observe(element_);

    return () => {
      observerCallback.cancel();
      observer.unobserve(element_);
    };
  }, [element]);

  return contentRect;
};

export default useElementContentRect;
